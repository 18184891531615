import React, { useState, useRef, useEffect } from 'react';
import { CiExport, CiMenuKebab } from 'react-icons/ci';
import { GrEdit } from 'react-icons/gr';
import { MdOutlineAutoDelete } from 'react-icons/md';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import { ColorRing } from 'react-loader-spinner';
import { FcSearch } from 'react-icons/fc';
import { BsEye } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import AddPatient from './AddPatient';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatient, fetchPatients, setFilters } from '../../../Redux/PatientSlice';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';

const PatientsInfo = () => {


    const navigate = useNavigate()
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const popUpRef = useRef(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [isAddPatientModal, setAddPatientModal] = useState(false)
    const { id, branch_id } = useSelector((state) => state.auth)
    const [localFilters, setLocalFilters] = useState({
        first_name: '',
        last_name: '',
        date_of_birth: '',
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        const newFilters = {
            ...localFilters,
            [name]: value.trim()
        };

        setLocalFilters(newFilters);
    };

    const dispatch = useDispatch();
    const {
        patients,
        loading,
        error,
        meta,
        sort,
        filters
    } = useSelector((state) => state.patients);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    useEffect(() => {
        dispatch(fetchPatients({
            page: currentPage,
            limit: itemsPerPage,
            userId: id,
            branchId: branch_id,
        }));
    }, [dispatch, currentPage]);



    // Enhanced search state
    const [searchCriteria, setSearchCriteria] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: ''
    });
    const [filteredPatients, setFilteredPatients] = useState(patients);

    // Sorting states
    const [showFirstNameSort, setShowFirstNameSort] = useState(true);
    const [showLastNameSort, setShowLastNameSort] = useState(true);

    // Toggle dropdown
    const togglePopUp = (event, id) => {
        event.stopPropagation();
        setSelectedDropdown(selectedDropdown === id ? null : id);
    };

    const handleSearch = () => {
        // if (!localFilters.first_name || !localFilters.last_name || !localFilters.date_of_birth) {
        //   toast.warning('Kindly fill all fields to search')
        //   return
        // }
        // Prepare the combined filter with non-empty values
        const combinedFilters = {};
        const filterFields = [];
        const filterValues = [];

        if (localFilters.first_name.trim() && localFilters.last_name.trim() && localFilters.date_of_birth.trim()) {
            filterFields.push('search_patient');
            filterValues.push(localFilters.first_name.trim());
            filterValues.push(localFilters.last_name.trim());
            filterValues.push(localFilters.date_of_birth.trim());

        }
        // if (localFilters.prescription_type.trim()) {
        //     filterFields.push('prescription_type');
        //     filterValues.push(localFilters.prescription_type.trim());
        // }

        // If there are any filters, create the combined filter
        if (filterFields.length > 0) {
            combinedFilters[filterFields.join(',')] = filterValues.join(',');
        }

        dispatch(setFilters(combinedFilters));

        dispatch(fetchPatients({
            userId: 574,
            branchId: 2,
            page: 1,
            limit: meta.per_page,
            sort,
            filters: combinedFilters
        }));
    };

    // Reset search
    const handleResetSearch = () => {
        setSearchCriteria({
            firstName: '',
            lastName: '',
            dateOfBirth: ''
        });
        setFilteredPatients(patients);
    };

    // Firstname sorting handlers
    const handleSortFirstNameAsc = () => {
        // const sortedPatients = [...filteredPatients].sort((a, b) =>
        //     a.firstName.localeCompare(b.firstName)
        // );
        // setFilteredPatients(sortedPatients);
        // setShowFirstNameSort(false);
    };

    const handleSortFirstNameDsc = () => {
        const sortedPatients = [...filteredPatients].sort((a, b) =>
            b.firstName.localeCompare(a.firstName)
        );
        setFilteredPatients(sortedPatients);
        setShowFirstNameSort(true);
    };

    // Lastname sorting handlers
    const handleSortLastNameAsc = () => {
        const sortedPatients = [...filteredPatients].sort((a, b) =>
            a.lastName.localeCompare(b.lastName)
        );
        setFilteredPatients(sortedPatients);
        setShowLastNameSort(false);
    };

    const handleSortLastNameDsc = () => {
        const sortedPatients = [...filteredPatients].sort((a, b) =>
            b.lastName.localeCompare(a.lastName)
        );
        setFilteredPatients(sortedPatients);
        setShowLastNameSort(true);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    if (loading) {
        return <div>Loading patients...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className='font-lufga'>
            <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Patients</p>
                <button
                    onClick={() => { navigate(-1) }}
                    className='text-sm font-semibold text-slate-600 hover:underline'>Go back
                </button>
            </div>

            {/* Search Section */}
            <div className={patients.length === 0 && patients.length > 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                <div className=''>
                    <div className='flex flex-wrap items-center gap-2'>
                        <div className='flex flex-col'>
                            <label className='text-xs'>First name</label>
                            <input
                                type='text'
                                name='first_name'
                                placeholder='First Name'
                                value={localFilters.first_name}
                                onChange={handleFilterChange}
                                className='w-28 focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs'
                            />
                        </div>
                        <div className='flex flex-col'>
                            <label className='text-xs'>Last name</label>
                            <input
                                type='text'
                                name='last_name'
                                placeholder='Last Name'
                                value={localFilters.last_name}
                                onChange={handleFilterChange}
                                className='w-28 focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs'
                            />
                        </div>
                        <div className='flex flex-col'>
                            <label className='text-xs'>Date of Birth</label>
                            <input
                                type='date'
                                name='date_of_birth'
                                placeholder='Date of Birth'
                                value={localFilters.date_of_birth}
                                onChange={handleFilterChange}
                                className='w-28 focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs'
                            />
                        </div>
                        {searchLoading ? (
                            <ColorRing
                                height="20"
                                width="20"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        ) : (
                            <div className='flex gap-2'>
                                <FcSearch
                                    onClick={handleSearch}
                                    className='hover:scale-110 cursor-pointer'
                                />
                                <button
                                    onClick={handleResetSearch}
                                    className='text-xs text-red-500 hover:underline'
                                >
                                    Reset
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row items-center gap-4'>

                    <div>
                     {hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin']) &&   (<Link to='/retail/prescriptions/add-patients'
                            state={{ requiredRoles: ['prescriptionViewer', 'prescriptionWriter', 'prescriptionAdmin'] }}
                        >
                            <button
                                className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">
                                Add Patient
                            </button>

                        </Link>)}

                    </div>
                </div>
            </div>



            {/* No Patients Scenario */}
            {patients.length === 0 && (
                <div className='flex justify-center items-center h-64 text-gray-500'>
                    <p className='text-xl'>No patients found</p>
                </div>
            )}


            {/* Patients Table */}
            {patients.length > 0 && (
                <table className='w-full text-xs px-2 table-auto overflow-auto shadow-md '>
                    <thead className='bg-green-200 shadow-lg sticky top-0'>
                        <tr>
                            <th className=''>No.</th>
                            <th>ID</th>
                            <th className="">
                                <div className='flex justify-center items-center gap-1'>
                                    <p>First Name</p>
                                    {showFirstNameSort ?
                                        <IoIosArrowRoundUp
                                            onClick={handleSortFirstNameAsc}
                                            className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                                        /> :
                                        <IoIosArrowRoundDown
                                            onClick={handleSortFirstNameDsc}
                                            className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                                        />
                                    }
                                </div>
                            </th>
                            <th className="">
                                <div className='flex justify-center items-center gap-1'>
                                    <p>Last Name</p>
                                    {showLastNameSort ?
                                        <IoIosArrowRoundUp
                                            onClick={handleSortLastNameAsc}
                                            className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                                        /> :
                                        <IoIosArrowRoundDown
                                            onClick={handleSortLastNameDsc}
                                            className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                                        />
                                    }
                                </div>
                            </th>
                            <th className=''>Date of Birth</th>
                            {/* <th className=''>Gender</th>
                            <th className=''>Address</th>
                            <th className=''>Phone</th> */}
                            <th className=''>ID Type</th>
                            <th className=''>ID Number</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody className='py-2'>
                        {patients.map((patient, index) => (
                            <tr
                                key={patient.id}
                                className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}
                            >
                                <td className='border-r-2 text-center py-4 h-20 flex-wrap'>{index + 1}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient.id}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.first_name}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.last_name}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>
                                    {new Date(patient.date_of_birth).toLocaleDateString()}
                                </td>
                                {/* <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.gender}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.address}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.phone}</td> */}
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.id_type}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{patient?.id_number}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap text-green-600 cursor-pointer'>
                                    <Link to={`/retail/prescriptions/view-patient/${patient.id}`}
                                        state={{ requiredRoles: ['prescriptionViewer', 'prescriptionWriter', 'prescriptionAdmin'] }}
                                        className=' hover:underline'
                                    >
                                        <span>view</span>

                                    </Link>
                                </td>

                               
                            </tr>


                        ))}
                    </tbody>
                </table>
            )}

           
        </div>
    );
};

export default PatientsInfo;