import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { addPatient, fetchPatientList } from '../../../Redux/PatientSlice';
import { addPatientAllergy } from '../../../Redux/AllergySlice';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';

const AddPatient = () => {
    const [patientInfo, setPatientInfo] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        address: '',
        phone: '',
        idType: '',
        idNumber: ''
    });
    const [allergies, setAllergies] = useState([
        {
            name: '',
            reaction: '',
            onset_date: ''
        }
    ]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false)

    const handlePatientInfoChange = (field, value) => {
        setPatientInfo({ ...patientInfo, [field]: value });
    };

    const handleAllergyChange = (index, field, value) => {
        const updatedAllergies = [...allergies];
        updatedAllergies[index][field] = value;
        setAllergies(updatedAllergies);
    };

    const addAllergy = () => {
        setAllergies([
            ...allergies,
            {
                name: '',
                reaction: '',
                onset_date: ''
            }
        ]);
    };

    const removeAllergy = (index) => {
        if (allergies.length > 1) {
            const updatedAllergies = [...allergies];
            updatedAllergies.splice(index, 1);
            setAllergies(updatedAllergies);
        }
    };

    const isPatientInfoFilled = Object.values(patientInfo).every(value => value !== '');
    const isAllergiesFilled = allergies.every(allergy => 
        allergy.name !== '' && allergy.reaction !== '' && allergy.onset_date !== ''
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate patient info
        if (!isPatientInfoFilled) {
            toast.warn('Please fill all patient information fields');
            return;
        }
setLoading(true)
        try {
            const patientData={
                first_name:patientInfo.firstName,
                last_name:patientInfo.lastName,
                date_of_birth: patientInfo.dob,
                gender:patientInfo.gender,
                phone_number:patientInfo.phone,
                id_number:patientInfo.idNumber,
                id_type:patientInfo.idType,
                address:patientInfo.address
            }
            const patientResult = await dispatch(addPatient(patientData));

            if (addPatient.fulfilled.match(patientResult)) {
                const patientId = patientResult?.payload?.data?.id;
            
                
                

                // If allergies are filled, add them
                if (isAllergiesFilled) {
                    const allergiesWithPatientId = allergies.map(allergy => ({
                        ...allergy,
                        patient_id: patientId
                    }));

                    await dispatch(addPatientAllergy({allergies,patientId}));
                }

                setPatientInfo({
                    firstName: '',
                    lastName: '',
                    dob: '',
                    gender: '',
                    address: '',
                    phone: '',
                    idType: '',
                    idNumber: ''
                });

                setAllergies([
                    {
                        name: '',
                        reaction: '',
                        onset_date: ''
                    }
                ]);

                toast.success('Patient added successfully');
            }
        } catch (error) {
            console.error('Failed to add patient:', error);
            toast.error('Failed to add patient');
        }finally{
            setLoading(false)
        }
    };
   



    return (
       
            <div className='font-lufga bg-white w-full '>
                 <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Add Patient</p>
                <button
                    onClick={() => { navigate(-1) }}
                    className='text-sm font-semibold text-slate-600 hover:underline'>Go back
                </button>
            </div>
                <form className='p-2 sm:p-8 mx-auto '>
                    
                    <div >
                        <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="firstName" className="block font-medium text-sm">First Name</label>
                                <input type="text" id="firstName" value={patientInfo.firstName} onChange={(e) => handlePatientInfoChange('firstName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="lastName" className="block font-medium text-sm">Last Name</label>
                                <input type="text" id="lastName" value={patientInfo.lastName} onChange={(e) => handlePatientInfoChange('lastName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="dob" className="block font-medium text-sm">Date of Birth</label>
                                <input type="date" id="dob" value={patientInfo.dob} onChange={(e) => handlePatientInfoChange('dob', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="gender" className="block font-medium text-sm">Gender</label>
                                <select
                                    className="w-full p-2 border rounded"
                                    value={patientInfo.gender}
                                    onChange={(e) => handlePatientInfoChange('gender', e.target.value)}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="address" className="block font-medium text-sm">Address</label>
                                <input type="text" id="address" value={patientInfo.address} onChange={(e) => handlePatientInfoChange('address', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="phone" className="block font-medium text-sm">Phone</label>
                                <input type="tel" id="phone" value={patientInfo.phone} onChange={(e) => handlePatientInfoChange('phone', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>

                            <div>
                                <label htmlFor="idType" className="block font-medium text-sm">ID Type</label>
                                <select
                                    className="w-full p-2 border rounded"
                                    value={patientInfo.idType}
                                    onChange={(e) => handlePatientInfoChange('idType', e.target.value)}
                                    required
                                >
                                    <option value="">Select ID type</option>
                                    <option value="Ghana Card">Ghana Card</option>
                                    <option value="Health Insuarance">Health Insurance</option>
                                    <option value="Passport">Passport</option>
                                    <option value="Driver's license">Driver's license</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="idNumber" className="block font-medium text-sm">ID Number</label>
                                <input type="text" id="idNumber" value={patientInfo.idNumber} onChange={(e) => handlePatientInfoChange('idNumber', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                        </div>

                        <div className={`sm:p-4 border-b sm:shadow rounded-md mt-4 ${!isPatientInfoFilled ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Allergies </h2>
                        {allergies.map((allergy, index) => (
                            <div key={index} className="border rounded sm:p-4 p-2 mb-4 relative">
                                {allergies.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => removeAllergy(index)}
                                        className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-600"
                                    >
                                        Remove
                                    </button>
                                )}
                                <div className="grid sm:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor={`allergy-name-${index}`} className="block font-medium text-sm">Allergy name</label>
                                        <input
                                            type="text" id={`allergy-name-${index}`} value={allergy.name} onChange={(e) => handleAllergyChange(index, 'name', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`allergy-reaction-${index}`} className="block font-medium text-sm">Reaction</label>
                                        <input type="text" id={`allergy-reaction-${index}`} value={allergy.reaction} onChange={(e) => handleAllergyChange(index, 'reaction', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`allergy-date-${index}`} className="block font-medium text-sm">Onset Date</label>
                                        <input type="date" id={`allergy-onset_date-${index}`} value={allergy.onset_date} onChange={(e) => handleAllergyChange(index, 'onset_date', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="flex space-x-2">
                            <button
                                type="button"
                                onClick={addAllergy}
                                className="bg-gray-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs"
                            >
                                Add allergy
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="flex justify-end mt-4">
                    {loading ? <ColorRing
                            height="20"
                            width="20"
                            loading={loading} /> : <button type="button" onClick={handleSubmit} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-sm">Submit</button>}
                    </div>
                </form>

            </div>
     
    );
}

export default AddPatient;
