import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { fetchPatientList } from '../../../Redux/PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, addPrescription, fetchPrescriptions } from '../../../Redux/PrescriptionSlice';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import AddPatient from './AddPatient';
import { Link } from 'react-router-dom';

const AddPrescription = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [prescriptionType, setPrescriptionType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const patients = useSelector((state) => state.patients.patients)
    const [selectedPatientId, setSelectedPatientId] = useState('')
    const [selectedPatientDetails, setSelectedPatientDetails] = useState(null)
    const [date_issued, setDate_issued] = useState('')
    const [comment, setComment] = useState('')


    const {
        loading,
        error,
    } = useSelector((state) => state.prescriptions)




    const [physicianInfo, setPhysicianInfo] = useState({
        firstName: '',
        lastName: '',
        npi: '',
        phone: '',
        hospitalName: '',
        hospitalAddress: ''
    });

    const [medications, setMedications] = useState([
        {
            name: '',
            strength: '',
            instructions: '',
            quantity: 0,
            refills: 0,
            date: ''
        }
    ]);

    // const handlePatientInfoChange = (field, value) => {
    //     setPatientInfo({ ...patientInfo, [field]: value });
    // };

    const handlePhysicianInfoChange = (field, value) => {
        setPhysicianInfo({ ...physicianInfo, [field]: value });
    };

    const handleMedicationChange = (index, field, value) => {
        const updatedMedications = [...medications];
        updatedMedications[index][field] = value;
        setMedications(updatedMedications);
    };

    const addMedication = () => {
        setMedications([
            ...medications,
            {
                name: '',
                strength: '',
                instructions: '',
                quantity: 0,
                allergies: '',
                refills: 0,
                date: ''
            }
        ]);
    };

    useEffect(() => {
        dispatch(fetchPatientList())
            .unwrap()
            .then()
            .catch((error) => console.error('Error fetching patients:', error));
    }, [dispatch])

    // useEffect(() => {
    //     if (searchTerm) {
            
    //         const filtered = patients?.filter((patient) =>
    //             patient?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             patient?.last_name?.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //         setFilteredPatients(filtered);
    //     } else {
    //         setFilteredPatients([]);
    //     }
    // }, [searchTerm, patients]);
    useEffect(() => {
        if (searchTerm) {
            const filtered = patients?.filter((patient) => {
                const fullName = `${patient?.first_name || ""} ${patient?.last_name || ""}`.toLowerCase();
                return fullName.includes(searchTerm.toLowerCase());
            });
            setFilteredPatients(filtered);
        } else {
            setFilteredPatients([]);
        }
    }, [searchTerm, patients]);
    
    const handleSelect = (patient) => {
        setSearchTerm(`${patient.first_name} ${patient.last_name}`);
        setSelectedPatientId(patient.id)
        setSelectedPatientDetails({
            phone: patient?.phone_number,
            dob: patient?.date_of_birth,
            address: patient?.address,
            gender: patient?.gender,
            idType: patient?.id_type,
            idNumber: patient?.id_number
        });
        setShowDropdown(false);
    };

    

    const removeMedication = (index) => {
        if (medications.length > 1) {
            const updatedMedications = [...medications];
            updatedMedications.splice(index, 1);
            setMedications(updatedMedications);
        }
    };
    const isPhysicianInfoFilled = Object.values(physicianInfo).every(value => value !== '');

    const handleSubmit = async () => {
        const prescriptionData = {
            type: prescriptionType,
            patient_id: selectedPatientId,
            physician_first_name: physicianInfo.firstName,
            physician_last_name: physicianInfo.lastName,
            physician_credential: physicianInfo.npi,
            physician_phone_number: physicianInfo.phone,
            physician_hospital_name: physicianInfo.hospitalName,
            physician_hospital_address: physicianInfo.hospitalAddress,
            medications,
            date_issued
        };

        try {
            const required = !selectedPatientId || !isPhysicianInfoFilled || !medications || !date_issued
            if (required) {
                toast.warn('Kindly fill all fields')
                return;
            }

            const resultAction = await dispatch(addPrescription(prescriptionData));

            if (addPrescription.fulfilled.match(resultAction)) {
                const prescriptionId = resultAction.payload.data.id;


                if (comment) {
                    await dispatch(addComment({
                        comment: comment,
                        prescription_id: prescriptionId
                    })).unwrap()
                        .then()
                        .catch((error) => console.error('Error adding comments:', error));;
                }

                toast.success('Prescription added successfully');

                setPrescriptionType('');
                setSearchTerm('');
                setSelectedPatientId('');
                setDate_issued('');
                setComment('');

                setPhysicianInfo({
                    firstName: '',
                    lastName: '',
                    npi: '',
                    phone: '',
                    hospitalName: '',
                    hospitalAddress: ''
                });

                setMedications([
                    {
                        name: '',
                        strength: '',
                        instructions: '',
                        quantity: 0,
                        refills: 0,
                        date: ''
                    }
                ]);
            }
        } catch (error) {
            console.error('Failed to add prescription:', error);
            toast.error('Failed to add prescription');
        }
    };

   


    return (
        <div className="font-lufga w-full">
            <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Add Prescription</p>
                <button
                    onClick={() => { navigate(-1) }}
                    className='text-sm font-semibold text-slate-600 hover:underline'>Go back
                </button>
            </div>
            <div>
                <form className='p-2 sm:p-4 max-w-[1440px] mx-auto'>
                    <div className="mb-4 w-fit">
                        <label className=" font-medium mb-2">
                            Prescription Type
                        </label>
                        <select
                            className="w-full p-2 border rounded"
                            value={prescriptionType}
                            onChange={(e) => setPrescriptionType(e.target.value)}
                            required
                        >
                            <option value="">Select Prescription Type</option>
                            <option value="verbal">Verbal/Phone</option>
                            <option value="written">Written/Paper</option>
                            {/* <option value="E-Prescription">E-Prescription</option> */}
                        </select>
                    </div>
                    <div className={`p-4 border-b shadow rounded-md w-full ${!prescriptionType ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Patient </h2>
                        <div className="relative">
                            <input
                                type="text"
                                id="patientSearch"
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setShowDropdown(true);
                                    setSelectedPatientDetails(null);
                                }}
                                onFocus={() => setShowDropdown(true)}
                                onBlur={() => setTimeout(() => setShowDropdown(false), 300)}
                                className="border px-2 py-1 rounded w-fit"
                                placeholder="Search patient by name"
                            />
                            {showDropdown && filteredPatients.length > 0 && (
                                <div className="absolute z-50 bg-white border rounded shadow-md mt-1 max-h-40 overflow-y-auto">
                                    {filteredPatients.map((patient) => (
                                        <div
                                            key={patient.id}
                                            onClick={() => handleSelect(patient)}
                                            className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                                        >
                                            <p>{`${patient.first_name} ${patient.last_name} `}</p>
                                            <p className='text-xs'> <span className=' text-gray-600'>DOB:</span> {patient?.date_of_birth} </p>
                                            <p className='text-xs'> <span className=' text-gray-600'>Phone:</span> {patient?.phone_number} </p>
                                            <p className='text-xs'> <span className=' text-gray-600'>ID:</span> {patient?.id_number} </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {showDropdown && searchTerm && filteredPatients.length === 0 && (
                                <div className="absolute z-50 bg-white border rounded shadow-md mt-1 p-4 w-full">
                                    <p className="text-red-500 mb-2">User not found</p>

                                    <Link to='/retail/prescriptions/add-patients'
                                        state={{ requiredRoles: ['prescriptionViewer', 'prescriptionWriter', 'prescriptionAdmin'] }}
                                    >
                                        <button
                                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm"
                                        >
                                            Add New Patient
                                        </button>

                                    </Link>

                                </div>
                            )}
                        </div>

                        {/* Display selected patient details */}
                        {selectedPatientDetails && (
                            <div className="mt-4 p-3 bg-gray-50 rounded">
                                <h3 className="text-md font-semibold mb-2">Patient Details</h3>
                                <div className="grid grid-cols-2 gap-2">
                                    <div>
                                        <span className="font-medium text-sm">Phone:</span>
                                        <p>{selectedPatientDetails.phone}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium text-sm">DOB:</span>
                                        <p>{selectedPatientDetails.dob}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium text-sm">Address:</span>
                                        <p>{selectedPatientDetails.address}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium text-sm">Gender:</span>
                                        <p>{selectedPatientDetails.gender}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium text-sm">ID Type:</span>
                                        <p>{selectedPatientDetails.idType}</p>
                                    </div>
                                    <div>
                                        <span className="font-medium text-sm">ID Number:</span>
                                        <p>{selectedPatientDetails.idNumber}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`p-4 mt-4 border-b shadow rounded-md ${!selectedPatientId ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Provider Info</h2>
                        <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="physicianFirstName" className="block font-medium text-sm">First Name</label>
                                <input type="text" id="physicianFirstName" value={physicianInfo.firstName} onChange={(e) => handlePhysicianInfoChange('firstName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="physicianLastName" className="block font-medium text-sm">Last Name</label>
                                <input type="text" id="physicianLastName" value={physicianInfo.lastName} onChange={(e) => handlePhysicianInfoChange('lastName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="npi" className="block font-medium text-sm">Provider ID</label>
                                <input type="text" id="npi" value={physicianInfo.npi} onChange={(e) => handlePhysicianInfoChange('npi', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="physicianPhone" className="block font-medium text-sm">Phone</label>
                                <input type="tel" id="physicianPhone" value={physicianInfo.phone} onChange={(e) => handlePhysicianInfoChange('phone', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="hospitalName" className="block font-medium text-sm">Hospital/Clinic</label>
                                <input type="text" id="hospitalName" value={physicianInfo.hospitalName} onChange={(e) => handlePhysicianInfoChange('hospitalName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="hospitalAddress" className="block font-medium text-sm">Hospital/Clinic Address</label>
                                <input type="text" id="hospitalAddress" value={physicianInfo.hospitalAddress} onChange={(e) => handlePhysicianInfoChange('hospitalAddress', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                        </div>
                    </div>

                    <div className={`p-4 border-b shadow rounded-md mt-4 ${!isPhysicianInfoFilled ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Medication Info</h2>
                        {medications.map((medication, index) => (
                            <div key={index} className="border rounded p-4 mb-4 relative">
                                {medications.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => removeMedication(index)}
                                        className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-600"
                                    >
                                        Remove
                                    </button>
                                )}
                                <div className="grid sm:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor={`medication-name-${index}`} className="block font-medium text-sm">Medication Name</label>
                                        <input
                                            type="text" id={`medication-name-${index}`} value={medication.name} onChange={(e) => handleMedicationChange(index, 'name', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-strength-${index}`} className="block font-medium text-sm">Strength (Dosage)</label>
                                        <input type="text" id={`medication-strength-${index}`} value={medication.strength} onChange={(e) => handleMedicationChange(index, 'strength', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>

                                    <div>
                                        <label htmlFor={`medication-quantity-${index}`} className="block font-medium text-sm">Quantity</label>
                                        <input type="number" id={`medication-quantity-${index}`} value={medication.quantity} onChange={(e) => handleMedicationChange(index, 'quantity', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-refills-${index}`} className="block font-medium text-sm">No. of Refills</label>
                                        <input type="number" id={`medication-refills-${index}`} value={medication.refills} onChange={(e) => handleMedicationChange(index, 'refills', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-instructions-${index}`} className="block font-medium text-sm">Instructions</label>
                                        <textarea id={`medication-instructions-${index}`} value={medication.instructions} onChange={(e) => handleMedicationChange(index, 'instructions', e.target.value)} className="border px-2 py-1 rounded w-full h-20"></textarea>
                                    </div>

                                </div>
                            </div>
                        ))}
                        <div className="flex space-x-2">
                            <button
                                type="button"
                                onClick={addMedication}
                                className="bg-gray-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs"
                            >
                                Add Medication
                            </button>
                        </div>
                    </div>

                    <div className={`p-4 mt-4 border-b shadow rounded-md `}>
                        <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="date_issued" className="block font-medium text-sm">Date Issued</label>
                                <input type="date" id="date_issued" value={date_issued} onChange={(e) => setDate_issued(e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="comments" className="block font-medium text-sm">Comments</label>
                                <textarea rows={3} type="text" id="comments" value={comment} onChange={(e) => setComment(e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>

                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        {loading ? <ColorRing
                            height="20"
                            width="20"
                            loading={loading} /> : <button type="button" onClick={handleSubmit} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-sm">Submit</button>}
                    </div>
                </form>
            </div>

          
        </div>
    );
};

export default AddPrescription;