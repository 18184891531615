import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { fetchPatient, updatePatient } from '../../../Redux/PatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AddAllergyModal from './AddAllergyModal';
import EditAllergyModal from './EditAllergyModal';
import { fetchPatientAllergies } from '../../../Redux/AllergySlice';
import { InfinitySpin } from 'react-loader-spinner';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';

const ViewPatient = () => {
    const navigate = useNavigate();
    const { patientId } = useParams();
    const dispatch = useDispatch();
    const { patientDetail,loading } = useSelector((state) => state.patients);
    const { allergies } = useSelector((state) => state.allergy);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedAllergy, setSelectedAllergy] = useState(null);

   

    const openEditModal = (allergyToEdit) => {
        setSelectedAllergy(allergyToEdit);
        setIsEditModalOpen(true);
    };



    const ID_TYPES = [
        { value: 'passport', label: 'Passport' },
        { value: 'drivers_license', label: "Driver's License" },
        { value: 'ghana_card', label: 'Ghana Card' },
        { value: 'nhis', label: 'NHIS' },
        { value: 'state_id', label: 'State ID' }
    ];


    const GENDER_OPTIONS = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
    ];
    const [isEditing, setIsEditing] = useState(false);
    const [patient, setPatient] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        idType: '',
        idNumber: '',
        gender: '',
        phoneNumber: '',
        address: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchPatient({ patientId })).unwrap();
                setPatient({
                    firstName: patientDetail?.first_name,
                    lastName: patientDetail?.last_name,
                    dateOfBirth: patientDetail?.date_of_birth,
                    idType: patientDetail?.id_type,
                    idNumber: patientDetail?.id_number,
                    gender: patientDetail?.gender,
                    phoneNumber: patientDetail?.phone_number,
                    address: patientDetail?.address,
                   
                });
            } catch (error) {
                console.error('Error fetching patient details:', error);
                toast.error('Failed to fetch patient details');
            }
        };

        fetchData();
    }, [dispatch, patientId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchPatientAllergies({ patientId })).unwrap();
            } catch (error) {
                console.error('Error fetching patient details:', error);
                toast.error('Failed to fetch patient details');
            }
        };

        fetchData();
    }, [dispatch, patientId]);

    

    

    const handleInputChange = (field, value) => {
        setPatient({ ...patient, [field]: value });
    };

    const handleSubmit = async () => {
        try {
            await dispatch(
                updatePatient({
                    patientId,
                    patientData: {
                        first_name: patient.firstName,
                        last_name: patient.lastName,
                        date_of_birth: patient.dateOfBirth,
                        id_type: patient.idType,
                        id_number: patient.idNumber,
                        gender: patient.gender,
                        phone_number: patient.phoneNumber,
                        address: patient.address,
                    },
                })
            ).unwrap();
            setIsEditing(false);
            toast.success('Patient details updated successfully');
        } catch (error) {
            console.error('Error updating patient details:', error);
            toast.error('Failed to update patient details');
        }
    };

   

    const handleRemoveAllergy = (indexToRemove) => {
        setPatient({
            ...patient,
            allergies: patient.allergies.filter((_, index) => index !== indexToRemove),
        });
    };

    const handleAllergyChange = (index, field, value) => {
        const updatedAllergies = [...patient.allergies];
        updatedAllergies[index][field] = value;
        setPatient({ ...patient, allergies: updatedAllergies });
    };

    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

   

    if(loading){
        return(
            <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
        )
    }

    return (
        <div className="font-lufga w-full">
            <div className="mb-2 mx-8 flex justify-between items-center">
                <div>
                    <p className="lg:text-3xl text-xl font-semibold text-slate-600">
                        Patient Details
                    </p>
                    <button
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="text-sm font-semibold text-slate-600 hover:underline"
                    >
                        Go back
                    </button>
                </div>
              { hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin']) && (<button
                    onClick={() => setIsEditing(!isEditing)}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                    {isEditing ? 'Cancel' : 'Edit'}
                </button>)}
            </div>

            <div className="p-4 border-b shadow rounded-md mb-4">
                <h2 className="text-lg font-medium mb-2">Patient Info</h2>
                <div className="grid sm:grid-cols-2 gap-4">
                    <div>
                        <label className="block font-medium text-sm">First Name</label>
                        {isEditing ? (
                            <input
                                type="text"
                                value={patient.firstName}
                                onChange={(e) => handleInputChange('firstName', e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        ) : (
                            <p className="text-gray-700">{patient.firstName || 'N/A'}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-sm">Last Name</label>
                        {isEditing ? (
                            <input
                                type="text"
                                value={patient.lastName}
                                onChange={(e) => handleInputChange('lastName', e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        ) : (
                            <p className="text-gray-700">{patient.lastName || 'N/A'}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-sm">Date of Birth</label>
                        {isEditing ? (
                            <input
                                type="date"
                                value={patient.dateOfBirth}
                                onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        ) : (
                            <p className="text-gray-700">{patient?.dateOfBirth || 'N/A'}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-sm">Gender</label>
                        {isEditing ? (
                            <select
                                value={patient.gender}
                                onChange={(e) => handleInputChange('gender', e.target.value)}
                                className="w-full p-2 border rounded"
                            >
                                <option value="">Select Gender</option>
                                {GENDER_OPTIONS.map((gender) => (
                                    <option key={gender.value} value={gender.value}>
                                        {gender.label}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <p className="text-gray-700">
                                {GENDER_OPTIONS.find(gender => gender.value === patient.gender)?.label || 'N/A'}
                            </p>
                        )}
                    </div>

                    <div>
                        <label className="block font-medium text-sm">Phone number</label>
                        {isEditing ? (
                            <input
                                type="text"
                                value={patient.phoneNumber}
                                onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        ) : (
                            <p className="text-gray-700">{patient.phoneNumber || 'N/A'}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-sm">Address</label>
                        {isEditing ? (
                            <input
                                type="text"
                                value={patient.address}
                                onChange={(e) => handleInputChange('address', e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        ) : (
                            <p className="text-gray-700">{patient.address || 'N/A'}</p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-sm">ID Type</label>
                        {isEditing ? (
                            <select
                                value={patient.idType}
                                onChange={(e) => handleInputChange('idType', e.target.value)}
                                className="w-full p-2 border rounded"
                            >
                                <option value="">Select ID Type</option>
                                {ID_TYPES.map((type) => (
                                    <option key={type.value} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <p className="text-gray-700">
                                {ID_TYPES.find(type => type.value === patient.idType)?.label || 'N/A'}
                            </p>
                        )}
                    </div>
                    <div>
                        <label className="block font-medium text-sm">ID Number</label>
                        {isEditing ? (
                            <input
                                type="text"
                                value={patient.idNumber}
                                onChange={(e) => handleInputChange('idNumber', e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        ) : (
                            <p className="text-gray-700">{patient.idNumber || 'N/A'}</p>
                        )}
                    </div>

                </div>
                {isEditing && (
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={handleSubmit}
                            type="button"
                            className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-sm"
                        >
                            Save Changes
                        </button>
                    </div>
                )}
            </div>

            <div className="p-4 border-b shadow rounded-md mb-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-medium">Allergies</h2>
                   {hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin']) && (<button
                        onClick={() => setIsModalOpen(true)}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    >
                        Add Allergy
                    </button>)}
                </div>
                <table className="w-full border-collapse text-sm">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="p-2 text-left">Name</th>
                            <th className="p-2 text-left">Onset Date</th>
                            <th className="p-2 text-left">Reaction</th>
                            {hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin'])&&<th className="p-2 text-left">Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {allergies?.map((allergy) => (
                            <tr key={allergy.id} className="border-b hover:bg-gray-50">
                                <td className="p-2">{allergy?.name || 'N/A'}</td>
                                <td className="p-2">{allergy?.onset_date || 'N/A'}</td>
                                <td className="p-2">{allergy?.reaction || 'N/A'}</td>
                                
                                  {hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin']) &&
                                  <td className="p-2">  (<button
                                        onClick={() => openEditModal(allergy)}
                                        className="text-blue-600 hover:underline"
                                    >
                                        Edit
                                    </button>)
                                    </td>
                                    }
                              
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <AddAllergyModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                patientId={patientId}
            />

            <EditAllergyModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                allergyToEdit={selectedAllergy}
            />
        </div>
    );
};

export default ViewPatient;