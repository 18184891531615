import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { GrEdit } from 'react-icons/gr';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { api_url } from '../../../config';
import StockComments from './StockComments';
import { FcCancel } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { hasPermission } from '../../../Redux/permissionSlice';
import api from '../../../utils/api';

const StockDetail = ({ data, products,fetchStocks,roles }) => {
    const [product, setProduct] = useState(data?.inventory.name || '')
    const [quantity, setQuantity] = useState(data?.quantity_received || '')
    const [quantity_left, setQuantity_left] = useState(data?.quantity || '')
    const [bill, setBill] = useState(data?.price_on_waybill)
    const [date_received, setDate_received] = useState(data?.date_received || '')
    const [expiryDate, setExpiryDate] = useState(data?.expiry_date || '')
    const [comment, setComment] = useState([])
    const [unitPrice, setUnitPrice] = useState(data?.price || '')
    const [batch, setBatch] = useState(data?.batch_number || '')
    const [stock_status, setStock_status] = useState('')
    const [stock_supplier, setStock_supplier] = useState(data?.contact?.name)
    const [stock_manufacturer, setStock_manufacturer] = useState(data?.manufacturer)
    const [sale_target, setSale_target] = useState(data?.sale_target || '')
    const [lowStockValue,setLowStockValue] = useState(data?.lowStockValue || '')
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = ['All', 'Active', 'Inactive']
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [editMode, setEditMode] = useState(false)
    console.log(data);
    const [productsInput, setProductsInput] = useState(data.inventory.name || '');
    const [productsSuggestions, setProductsSuggestions] = useState([]);
    const [showProductsSuggestions, setShowProductsSuggestions] = useState(false);
    const productsInputRef = useRef(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const { id, branch_id, token } = useSelector((state) => state.auth);
    const [view, setView] = useState('details');
    

    const handleProductsInputChange = (e) => {
        const value = e.target.value;
        setProductsInput(value);

        // Filter  based on the input value
        const filteredProductSuggestions = products.filter(item =>
            item.product_name && item.product_name.toLowerCase().includes(value.toLowerCase())
        );

        // Debugging logs
        console.log('Filtered Suggestions:', filteredProductSuggestions);

        setProductsSuggestions(filteredProductSuggestions);
        setShowProductsSuggestions(true);
    };

    const handleProductFocus = () => {
        // Show all wholesalers on focus
        setProductsSuggestions(products);
        setShowProductsSuggestions(true);

    };

    const handleSelectProducts = (selectedProduct) => {
        setProductsInput(selectedProduct.product_name );
        setSelectedProductId(selectedProduct.id);
        setStock_supplier(selectedProduct?.contact.name)
        setStock_manufacturer(selectedProduct?.manufacturer)
        setShowProductsSuggestions(false);
    };



    const handleEditMode = () => {
        // Toggle edit mode
        setEditMode(!editMode);

        // If entering edit mode, set the form fields to the current wholesaler's details
        if (!editMode) {
            setProductsInput(data?.inventory.name || '')
            setQuantity(data?.quantity_received || '')
            setDate_received(data?.date_received || '')
            setExpiryDate(data?.expiry_date || '')
            setUnitPrice(data?.price || '')
            setSale_target(data?.sale_target || '')
            setLowStockValue(data?.low_stock_value ||'')
            setBatch(data?.batch_number || '')

        }
    };

    useEffect(() => {
        setProductsInput(data?.inventory.name || '')
        setQuantity(data?.quantity_received || '')
        setDate_received(data?.date_received || '')
        setExpiryDate(data?.expiry_date || '')
        setUnitPrice(data?.price || '')
        setSale_target(data?.sale_target || '')
        setStock_supplier(data?.contact?.name)
        setBatch(data?.batch_number || '')
        setStock_manufacturer(data?.manufacturer)

    }, [data]);



    const handleViewComments = () => {
        setView('comments');
    };

    const handleViewDetails = () => {
        setView('details');
    };

    const updateStock = async (e) => {
        // Prevent default form submission if an event is passed
        if (e) {
            e.preventDefault();
        }

        // Gather stock details from state or props
        const stockDetails = {
            inventory_id: data.inventory.id,
            quantity_received: quantity,
            price: unitPrice,
            date_received,
            expiry_date: expiryDate,
            sale_target:quantity * unitPrice,
            price_on_waybill: bill,
            low_stock_value:lowStockValue,
            batch_number: batch
        };

        const quantityPrecision = (quantity - data.quantity_received) + data.quantity;

        // Check if any required field is missing
        const required = !data.id || !quantity || !unitPrice || !date_received || !expiryDate || !sale_target;
        if (required) {
            setError('Please fill all required fields');
            setTimeout(() => setError(''), 6000);
            return;
        }
        if(quantityPrecision < 0){
            setError('Quantity received cannot be less than quantity sold')
            return;
        }

        // Check if the user is offline
        if (!navigator.onLine) {
            toast("You're offline");
            return;
        }
        const target_precision = unitPrice * quantity;
        if(sale_target > target_precision){
            setError("Target exceeds stock's total value")
            return;
        }

        try {
            setLoading(true);

            // Send POST request to add stock details
            const response = await api.put(`${api_url}/api/v1/wholesaler/user/${id}/branch/${branch_id}/stock/${data.id}`, stockDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });

            // Success feedback
            toast.success('Stock updated successfully');
            fetchStocks(1,50,'');
            setStock_status('Stock updated successfully');
            setTimeout(() => {
                setStock_status('');
            }, 3000);
        } catch (error) {
            const errorMsg = error?.response?.data?.error || 'An error occurred';
            setError(errorMsg);
            toast.error(errorMsg);
            setTimeout(() => {
                setError('');
            }, 3000);

        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetchComments()
    // }, [])
    return (
        <div className='font-lufga'>
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                </div>
            )}
            <div>
                <div className=' mb-6 flex flex-col sm:flex-row justify-between  items-center '>
                    <div className='flex items-center gap-4 my-1'>
                        {view === 'details' && (hasPermission(roles,['stockWriter', 'stockAdmin'])&&(
                            <button onClick={handleEditMode} className='font-lufga text-center font-semibold text-neutral-500 p-2 rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200 '>   {!editMode ? <><GrEdit /><span className='text-xs'>Edit</span></> : <><FcCancel /><span className='text-xs'>Cancel</span></>} </button>

                        ))
                          }

                        {view === 'details' ? <button onClick={handleViewComments} className='font-lufga text-center font-semibold text-neutral-500 p-2 text-xs rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200'>
                            View comments
                        </button>  : <button onClick={handleViewDetails} className='font-lufga text-center font-semibold text-neutral-500 p-2 text-xs rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200'>
                                View details
                            </button>}

                        {/* <button onClick={resetPassword} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>   <MdLockReset /><span className='text-xs'>Reset Password</span> </button>
                            <button onClick={() => setStatus_history(!status_history)} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>  <MdHistoryToggleOff /><span className='text-xs'>status history</span> </button> */}
                    </div>
                    {/* <div className='flex items-center gap-3 '>
                            <label className="text-sm font-lufga" >Status</label>
                            <Switch
                              
                                checkedChildren="active"
                                unCheckedChildren="inactive"
                                // defaultChecked={checker}
                                // onChange={handleUserStatus}
                                disabled={!editMode}

                            />
                        </div> */}
                </div>

            </div >

            {view === 'details' && (
                <form className="grid gap-4 mb-2 md:grid-cols-2">
                    {loading && (
                        <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                            <InfinitySpin color="#059212" loading={loading} size={36} />
                        </div>
                    )}
                    <div ref={productsInputRef} className="relative flex flex-col gap-2.5">
                        <label className="text-sm font-lufga flex items-center gap-1">Product name<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="text"
                            value={editMode ? productsInput : data?.inventory.name}
                            onChange={handleProductsInputChange}
                            onFocus={handleProductFocus}
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 `}
                            placeholder="Type product..."
                            required
                            disabled={true}
                        />
                        {showProductsSuggestions && productsSuggestions.length > 0 && (
                            <div className="absolute z-10 w-full bg-white border max-h-[150px] overflow-auto border-gray-300 rounded-lg shadow-lg mt-20">
                                {productsSuggestions.map((product, index) => (
                                    <div
                                        key={index}
                                        className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                        onClick={() => handleSelectProducts(product)}
                                    >
                                        <p className='text-xs hover:bg-blue-100 px-1 rounded-md shadow-sm'>
                                            {product?.product_name} - {product?.manufacturer}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Manufacturer</label>
                        <input
                            value={stock_manufacturer}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500`}
                            required
                            disabled={true}
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Batch No.</label>
                        <input
                            type="text"
                            value={editMode ? batch : data?.batch_number}
                            onChange={(e) => setBatch(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled={!editMode}
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Date Received<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="date"
                            value={editMode ? date_received : data?.date_received}
                            onChange={(e) => setDate_received(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled={!editMode}
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Expiry Date<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="date"
                            value={editMode ? expiryDate : data?.expiry_date}
                            onChange={(e) => setExpiryDate(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            disabled={!editMode}
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Quantity Received<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="number"
                            value={editMode ? quantity : data?.quantity_received}
                            onChange={(e) => setQuantity(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled={!editMode}
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Cost on way bill</label>
                        <input
                        type='number'
                             value={editMode ? bill : data?.price_on_waybill}
                             onChange={(e) => { setBill(e.target.value) }}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled={!editMode}
                        />
                    </div>
                  
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Quantity Remaining<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="number"
                            value={editMode ? quantity_left : data?.quantity}
                            onChange={(e) => setQuantity_left(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md `}
                            required
                            disabled
                        />
                    </div>
                   
                   
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Unit Price<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="number"
                            value={editMode ? unitPrice : data?.price}
                            onChange={(e) => setUnitPrice(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled={!editMode}
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Estimated Sale Target<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="number"
                            value={quantity_left*unitPrice}
                            // onChange={(e) => setSale_target(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Estimated Profit<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="number"
                            value={(unitPrice * quantity_left) - (bill * quantity_left).toFixed(2) }
                            placeholder=""
                            className={`px-3 py-2 border border-gray-100 rounded-md focus:outline-none focus:border-blue-500 ${(unitPrice * quantity) - (bill * quantity) < 0 ? 'text-red-500' : 'text-blue-500'
                            }`}             
                                           required
                            disabled
                        />
                    </div>
                    <div className='flex flex-col gap-2.5'>
                        <label className="text-sm font-lufga flex items-center gap-1">Re-order Level<span className='font-serif text-red-400'>*</span></label>
                        <input
                            type="number"
                            value={editMode ? lowStockValue : data?.low_stock_value}
                            onChange={(e) => setLowStockValue(e.target.value)}
                            placeholder=""
                            className={`px-3 py-2 rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                            required
                            disabled={!editMode}
                        />
                    </div>
                   
                </form>
            )}
            <div className='flex justify-between items-center'>

                {editMode && <button onClick={updateStock} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update</button>}
                {stock_status ? <div>
                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{stock_status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}
            </div>
            {view === 'comments' && (
                <StockComments data={data} roles={roles} />
            )}
        </div >
    );
}

export default StockDetail;
