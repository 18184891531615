export const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'pending':
      case 'processing':
      case 'awaiting_confirmation':
      case 'pick_up':
        return 'bg-yellow';
      case 'fulfilled':
        return 'bg-green';
      case 'cancelled':
        return 'bg-red';
      default:
        return 'bg-transparent';
    }
  };