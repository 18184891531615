import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import { clearAuthData, selectRoles } from '../Redux/AuthSlice';
import { hasPermission } from '../Redux/permissionSlice';

const PrivateRoute = ({ type }) => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const productType = useSelector((state) => state.auth.product_type);
  const selectedRoles = useSelector(selectRoles);
  const userRoles = Array.isArray(selectedRoles) 
    ? selectedRoles 
    : selectedRoles?.split(',').map(role => role.trim());
  
  const dispatch = useDispatch();

  const isAuthenticated = token && token !== "";
  const isCorrectProductType = productType === type;
  

  const whitelistedRoutes = [
    '/wholesale/summary', 
    '/retail/summary', 
    '/wholesale/profile', 
    '/retail/profile',
    '/retail/prescriptions/add'  // Add this route for testing...I go remove am later
  ];

  const hasRequiredPermissions = 
    whitelistedRoutes.includes(location.pathname) ||
    hasPermission(userRoles, location.state?.requiredRoles || []);

  useEffect(() => {
    if (!isAuthenticated || !isCorrectProductType) {
      dispatch(clearAuthData());
    }
  }, [dispatch, isAuthenticated, isCorrectProductType]);

  return isAuthenticated && isCorrectProductType && hasRequiredPermissions ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;