import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref'
import { FaChevronDown, FaRegEye, FaUserClock, FaUserLock } from "react-icons/fa6";
import { toast } from 'react-toastify';
import { useDBContext } from '../../../context/DBContext';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { IoIosArrowRoundDown, IoIosArrowRoundUp, IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import InventoryForm from './InventoryForm';
import EditInventory from './EditInventory';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MdOutlineAutoDelete, MdOutlineDeleteForever } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { api_url } from '../../../config';
import DeleteInventory from './DeleteInventory';
import { CiExport, CiMenuKebab } from 'react-icons/ci';
import { GrEdit } from 'react-icons/gr';
import { useLocation } from 'react-router';
import { FcSearch } from 'react-icons/fc';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';
import api from '../../../utils/api';
import { getPageNumbers } from '../../../utils/getPageNumbers';
import exportFromJSON from 'export-from-json';

const InventoryInfo = () => {
    const [showModal, setShowModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [inventories, setInventories] = useState([])
    const [inventories_limit, setInventories_limit] = useState([])
    const [selectedInventory, setSelectedInventory] = useState()
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = ['All', 'Active', 'Inactive']
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [limit, setLimit] = useState(50)
    const [newLimit, setNewLimit] = useState(50);
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [sortby, setSortby] = useState('-created_at')
    const [showNameSort, setShowNameSort] = useState(false)
    const [showSupplierSort, setShowSupplierSort] = useState(false)
    const [showManufacturerSort, setShowManufacturerSort] = useState(false)
    const [showDateSort, setShowDateSort] = useState(false)
    const [metadata, setMetadata] = useState('')
    const [search, setSearch] = useState('')
    const [hasSearched, setHasSearched] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false)

    const location = useLocation();


    const btnConditionalRendering = (specificPath) => {
        return location.pathname === specificPath;
    };
    const shouldRenderButton = btnConditionalRendering('/retail/dashboard');


    const handleSortNameAsc = () => {
        setSortby('product_name')
        setShowNameSort(!showNameSort)
    }

    const handleSortNameDsc = () => {
        setSortby('-product_name')
        setShowNameSort(!showNameSort)

    }
    const handleSortSupplierAsc = () => {
        setSortby('contact_name')
        setShowSupplierSort(!showSupplierSort)
    }

    const handleSortSupplierDsc = () => {
        setSortby('-contact_name')
        setShowSupplierSort(!showSupplierSort)

    }
    const handleSortManufacturerAsc = () => {
        setSortby('manufacturer')
        setShowManufacturerSort(!showManufacturerSort)
    }

    const handleSortManufacturerDsc = () => {
        setSortby('-manufacturer')
        setShowManufacturerSort(!showManufacturerSort)

    }

    const handleSortDateAsc = () => {
        setSortby('created_at')
        setShowDateSort(!showDateSort)
    }

    const handleSortDateDsc = () => {
        setSortby('-created_at')
        setShowDateSort(!showDateSort)

    }



    const modalRef = useRef()
    const popUpRef = useRef()
    const togglePopUp = (event, index, user) => {
        event.stopPropagation();
        event.preventDefault();
        if (index === selectedDropdown) {
            setSelectedDropdown(null);
            // Deselect branch if clicking on the same dropdown
        } else {
            setSelectedDropdown(index);

        }
    };

    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const showEditModal = (event, inventory) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        setEditModal(!editModal)
        setSelectedInventory(inventory)
    }
    const showDeleteModal = (event, inventory) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        setDeleteModal(!deleteModal)
        setSelectedInventory(inventory)
    }
    const toggleInventoryForm = () => {
        setShowModal(!showModal)
    };
    const handleLimitChange = (e) => {
        setNewLimit(parseInt(e.target.value));
    };

    const applyNewLimit = () => {
        setLimit(newLimit);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const { id, branch_id, token } = useSelector((state) => state.auth)
    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    const fetchInventories = async (page, limit, searchQuery) => {
        setLoading(loading)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const params = {
            page: page || 1,
            limit: limit || 50,
            sort: sortby,
            search: `${searchQuery || ''}`

        };
        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/inventory`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            })
            const data = response.data.data
            const meta = response.data.meta
            setInventories(data)
            setMetadata(meta)
            setInventories_limit(response.data.meta.total);
            setLastPage(response.data.meta.last_page)

        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchInventories(currentPage, limit)
        fetchSuppliers()

    }, [id, token, currentPage, limit, sortby])



    const [suppliers, setSuppliers] = useState([])
    const fetchSuppliers = async () => {

        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/contact/inbranch`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = response.data.data
            setSuppliers(data)
        } catch (error) {
            console.log(error);

        }
    }






    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
            setEditModal(false)
            setDeleteModal(false)
        }
    };

    useEffect(() => {
        // Attach the event listener when the modal is open
        if (showModal || editModal || deleteModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            // Remove the event listener when the modal is closed
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // Clean up function to remove event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal, editModal, deleteModal]);

    const handleSearchClick = async (searchQuery) => {
        setHasSearched(true);
        setSearchLoading(!searchLoading)
        try {
            await fetchInventories(1, 50, searchQuery);;
            setHasSearched(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchLoading(false)
        }
    };

    const exportFunc = () => {
        const data = inventories?.map(i => ({
            id: i.id,
            Product_name: i.product_name,
            Supplier: i.contact.name,
            Manufacturer: i.manufacturer,
            Public: i.public_inventory,
            Description: i.description,
            Date_created: new Date(i?.created_at).toLocaleDateString()


        }))
        const fileName = 'Inventories'
        const exportType = exportFromJSON.types.csv

        exportFromJSON({ data, fileName, fields: ["id", "Product_name", "Supplier", "Manufacturer", "Public", "Description", "Date_created"], exportType })

    }



    return (
        <div className='font-lufga'>
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
            )}
            <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Inventories ({metadata?.total || '-'})</p>
            </div>
            <div className={inventories.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                {currentPage === 1 ? <div className='flex items-center gap-2'>
                    <p className='text-sm'  >List per page</p>
                    <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit" value={newLimit} onChange={handleLimitChange} />
                    <button className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" onClick={applyNewLimit}>Apply</button>
                </div> :
                    <div>
                        <p className='text-sm'  >List</p>
                    </div>}
                <div className='flex flex-col sm:flex-row items-center gap-4'>
                    <div className='flex items-center gap-2'>
                        <input type='text' placeholder='Search inventories' value={search} onChange={(e) => { setSearch(e.target.value) }} className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                        {searchLoading ? <ColorRing
                            height="20"
                            width="20"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : <FcSearch onClick={() => { handleSearchClick(search) }} className='hover:scale-110 cursor-pointer' />}
                    </div>
                    {shouldRenderButton ? null : <div>
                        {hasPermission(roles, ['inventoryWriter', 'inventoryAdmin']) &&
                            <button onClick={toggleInventoryForm} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add Inventory</button>
                        }
                    </div>}
                    <div>
                        <button
                            onClick={exportFunc}
                            className="bg-gray-200  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-sm text-xs flex items-center gap-1">
                            <CiExport />
                            <p>Export</p>
                        </button>

                    </div>
                </div>
            </div>
            {!loading && (<div className='w-full '>
                {inventories.length > 0 ? (
                    <div>
                        <div className='rounded-lg  shadow-md max-h-[60vh] overflow-auto'>

                            <table className='w-full text-xs  px-2 table-auto  overflow-auto'>
                                <thead className='bg-green-200 shadow-lg sticky top-0'>
                                    <tr>
                                        <th className=''>No.</th>
                                        <th >ID</th>
                                        <th className=" ">
                                            <div className='flex justify-center items-center gap-1'>
                                                <p>Product name</p>
                                                {showNameSort ? <IoIosArrowRoundUp onClick={handleSortNameAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                    <IoIosArrowRoundDown onClick={handleSortNameDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}                                                </div>
                                        </th>
                                        <th className=''>
                                            <div className='flex justify-center items-center gap-1'>
                                                <p>Supplier</p>
                                                {showSupplierSort ? <IoIosArrowRoundUp onClick={handleSortSupplierAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                    <IoIosArrowRoundDown onClick={handleSortSupplierDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                            </div>
                                        </th>
                                        <th className=''>
                                            <div className='flex justify-center items-center gap-1'>
                                                <p>Manufacturer</p>
                                                {showSupplierSort ? <IoIosArrowRoundUp onClick={handleSortManufacturerAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                    <IoIosArrowRoundDown onClick={handleSortManufacturerDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                            </div>
                                        </th>

                                        <th >Description</th>
                                        <th className=" ">
                                            <div className='flex justify-center items-center'>
                                                <p>Date created</p>
                                                {showDateSort ? <IoIosArrowRoundUp onClick={handleSortDateAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                    <IoIosArrowRoundDown onClick={handleSortDateDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}                                                </div>
                                        </th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody className='py-2'>
                                    {inventories.map((data, index) => (
                                        <tr key={data.id} className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}>
                                            <td className='border-r-2 text-center py-4 h-20 flex-wrap'>{index + 1}</td>
                                            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.id}</td>
                                            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.product_name}</td>
                                            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.contact?.name}</td>
                                            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.manufacturer}</td>
                                            <td className='border-x-2 text-center py-4 h-20 flex-wrap w-96'>{data?.description || '-'}</td>
                                            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{new Date(data?.created_at).toLocaleDateString()}</td>
                                            {/* <td className='flex items-center justify-center gap-[1px]  h-20 '>
                                                <span onClick={() => { showEditModal(data) }} className='text-green-500 hover:border-b text-center font-semibold hover:border-b-green-500 cursor-pointer'>
                                                <FaRegEdit size={22} />
                                                </span>
                                                <span className='text-red-500 hover:border-b text-center font-semibold hover:border-b-red-500 cursor-pointer'>
                                                <MdOutlineDeleteForever onClick={() => { showDeleteModal(data) }}  size={22} />
                                                </span>
                                            </td> */}
                                            <td className='flex items-center justify-end h-20 relative'>
                                                <CiMenuKebab className='cursor-pointer' size={18} onClick={(event) => togglePopUp(event, data.id, data)} />
                                                {selectedDropdown === data.id && (
                                                    <div ref={popUpRef} className='absolute flex flex-col z-10 gap-2 top-8 right-0 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'>
                                                        {/* <div className='cursor-pointer justify-center  hover:bg-slate-50 flex gap-2 items-center'>
                                                                <FaRegEye />
                                                                <p className=' text-xs sm:text-sm ' >
                                                                    View
                                                                </p>
                                                            </div> */}
                                                        {hasPermission(roles, ['inventoryWriter', 'inventoryAdmin']) && (<div onClick={(event) => { showEditModal(event, data) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                            <GrEdit />
                                                            <p className=' text-xs sm:text-sm' >
                                                                Edit
                                                            </p>
                                                        </div>)}
                                                        {hasPermission(roles, ['inventoryAdmin']) && (<div onClick={(event) => { showDeleteModal(event, data) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                            <MdOutlineAutoDelete />
                                                            <p className=' text-xs sm:text-sm' >
                                                                Delete
                                                            </p>
                                                        </div>)}

                                                        {/* Add other dropdown items as needed */}
                                                    </div>
                                                )}
                                            </td>

                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className='flex items-center gap-4 mt-4'>
                            <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-green-600'}>Previous</button>
                            <div className='flex items-center gap-3'>
                                {Array.from({ length: lastPage }, (_, i) => (
                                    <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                ))}
                            </div>
                            <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-green-600'}>Next</button>
                        </div> */}
                        <div className='flex items-center gap-4 mt-4'>
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                            >
                                Previous
                            </button>
                            <div className='flex items-center gap-3'>
                                {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                                    <React.Fragment key={index}>
                                        {pageNumber === '...' ? (
                                            <span className="text-xs">...</span>
                                        ) : (
                                            <button
                                                onClick={() => handlePageClick(pageNumber)}
                                                className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                            >
                                                {pageNumber}
                                            </button>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === lastPage}
                                className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        {hasSearched ? <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                            <p className="text-gray-500 text-center text-xs">No inventories found</p>
                            <button onClick={() => { handleSearchClick() }} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">{searchLoading ? <ColorRing
                                height="20"
                                width="20"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            /> : "Go back"}</button>
                        </div> : <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                            <p className="text-gray-500 text-center text-xs">Get Started</p>
                            {hasPermission(roles, ['inventoryWriter', 'inventoryAdmin']) && !shouldRenderButton && (<button onClick={toggleInventoryForm} className="bg-green-500  hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Inventory</button>
                            )}
                        </div>}
                    </div>
                )}
                {editModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                            <IoMdCloseCircle size={24} onClick={() => setEditModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                            <EditInventory toggle={() => setEditModal(false)} inventory={selectedInventory} suppliers={suppliers} fetchInventories={fetchInventories} />
                        </div>
                    </div>
                )}
                {deleteModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                            <IoMdCloseCircle size={24} onClick={() => setDeleteModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                            <DeleteInventory toggle={() => setDeleteModal(false)} data={selectedInventory} fetchInventories={fetchInventories} />
                        </div>
                    </div>
                )}

            </div>)}

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                        <IoMdClose size={24} onClick={toggleInventoryForm} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                        <InventoryForm suppliers={suppliers} toggle={toggleInventoryForm} fetchInventories={fetchInventories} />
                    </div>
                </div>
            )}



        </div>


    );
}

export default InventoryInfo;
