import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePatientAllergy } from '../../../Redux/AllergySlice';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

const EditAllergyModal = ({ isOpen, onClose, allergyToEdit}) => {
  const dispatch = useDispatch();
  const {patientId} = useParams()
  const [currentAllergy, setCurrentAllergy] = useState({
    name: '',
    onset_date: '',
    reaction: ''
  });


  useEffect(() => {
    if (allergyToEdit) {
      setCurrentAllergy({
        name: allergyToEdit.name || '',
        onset_date: allergyToEdit.onset_date || '',
        reaction: allergyToEdit.reaction || ''
      });
    }
  }, [allergyToEdit]);



  const handleSaveChanges = () => {
    if (currentAllergy.name.trim()) {
      dispatch(updatePatientAllergy({
        patientId,
        allergyId: allergyToEdit.id, 
        allergies: {
          ...currentAllergy
        }
      })).then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {

          onClose();
        } else {
          console.error('Failed to update allergy', response.payload);
          toast.error('Failed to update allergy');
        }
      }).catch((error) => {
        console.error('Error updating allergy', error);
        toast.error('Error updating allergy');
      });
    }
  };

  // If modal is not open or no allergy to edit, return null
  if (!isOpen || !allergyToEdit) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Edit Allergy</h2>
          <p className="text-sm text-gray-600 mt-1">Modify details for this allergy</p>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="editName" className="text-right font-medium text-gray-700">
              Name
            </label>
            <input
              id="editName"
              value={currentAllergy.name}
              onChange={(e) => setCurrentAllergy({...currentAllergy, name: e.target.value})}
              className="col-span-3 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Allergy name"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="editOnsetDate" className="text-right font-medium text-gray-700">
              Onset Date
            </label>
            <input
              id="editOnsetDate"
              type="date"
              value={currentAllergy.onset_date}
              onChange={(e) => setCurrentAllergy({...currentAllergy, onset_date: e.target.value})}
              className="col-span-3 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="editReaction" className="text-right font-medium text-gray-700">
              Reaction
            </label>
            <textarea
              id="editReaction"
              value={currentAllergy.reaction}
              onChange={(e) => setCurrentAllergy({...currentAllergy, reaction: e.target.value})}
              className="col-span-3 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Describe the allergic reaction"
              rows="3"
            />
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAllergyModal;