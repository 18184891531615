import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { fetchPrescription, updatePrescription, updatePrescriptionStatus } from '../../../Redux/PrescriptionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getStatusBackgroundColor } from '../../../utils/getStatusBackground.Color';
import PrescriptionComments from './PrescriptionComments';
import { selectRoles } from '../../../Redux/AuthSlice';
import { hasPermission } from '../../../Redux/permissionSlice';

const ViewEditPrescription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { prescriptionDetail } = useSelector((state) => state.prescriptions);

    const [isEditing, setIsEditing] = useState(false);
    const [prescriptionType, setPrescriptionType] = useState('');
    const [date_issued, setDate_issued] = useState('');
    const [status, setStatus] = useState('')
    const { prescriptionId } = useParams()
    const [isCommentPaneOpen, setIsCommentPaneOpen] = useState(false);
    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    const toggleCommentPane = () => {
        setIsCommentPaneOpen(!isCommentPaneOpen);
    };
   



    const [patientInfo, setPatientInfo] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        address: '',
        phone: '',
        idType: '',
        idNumber: ''
    });

    const [physicianInfo, setPhysicianInfo] = useState({
        firstName: '',
        lastName: '',
        npi: '',
        phone: '',
        hospitalClinic: '',
        hospitalAddress: ''
    });

    const [medications, setMedications] = useState([
        {
            name: '',
            strength: '',
            instructions: '',
            quantity: 0,
            allergies: '',
            refills: 0,
            date: ''
        }
    ]);

    const handlePrescriptionTypeChange = (value) => {
        setPrescriptionType(value);
    };
    const handleDateIssuedChange = (value) => {
        setDate_issued(value);
    };

    const handlePhysicianInfoChange = (field, value) => {
        setPhysicianInfo({ ...physicianInfo, [field]: value });
    };

    const handleMedicationChange = (index, field, value) => {
        // Create a new array with spread operator to avoid mutating original state directly
        const updatedMedications = medications.map((med, medIndex) =>
            medIndex === index
                ? { ...med, [field]: value }
                : med
        );

        // Update state with new array
        setMedications(updatedMedications);
    };

    const addMedication = () => {
        setMedications([
            ...medications,
            {
                name: '',
                strength: '',
                instructions: '',
                quantity: 0,
                refills: 0,
            }
        ]);
    };

    const removeMedication = (index) => {
        if (medications.length > 1) {
            const updatedMedications = [...medications];
            updatedMedications.splice(index, 1);
            setMedications(updatedMedications);
        }
    };



    const renderInput = (type, value, onChange, options = {}) => {
        const { placeholder, required, className, readOnly = false, ...rest } = options;

        if (!isEditing || readOnly) {
            return <p className="text-gray-700">{value || 'N/A'}</p>;
        }

        switch (type) {
            case 'select':
                return (
                    <select
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className={`w-full p-2 border rounded ${className}`}
                        required={required}
                        {...rest}
                    >
                        {options.children}
                    </select>
                );
            case 'textarea':
                return (
                    <textarea
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className={`w-full p-2 border rounded ${className}`}
                        {...rest}
                    />
                );
            default:
                return (
                    <input
                        type={type}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className={`w-full p-2 border rounded ${className}`}
                        placeholder={placeholder}
                        required={required}
                        {...rest}
                    />
                );
        }
    };


    useEffect(() => {
        if (prescriptionId) {
            dispatch(fetchPrescription(prescriptionId))
                .unwrap()
                .then((payload) => {
                    // Populate patient info from payload (read-only)
                    setPatientInfo({
                        id: payload?.data?.id,
                        firstName: payload?.data?.patient_first_name,
                        lastName: payload?.data?.patient_last_name,
                        phone: payload?.data?.patient_phone_number,
                        dob: payload?.data?.patient_date_of_birth,
                        address: payload?.data?.patient_address,
                        gender: payload?.data?.patient_gender,
                        idType: payload?.data?.patient_id_type,
                        idNumber: payload?.data?.patient_id_number
                    });

                    // Populate physician info with initial values
                    setPhysicianInfo({
                        firstName: payload?.data?.physician_first_name,
                        lastName: payload?.data?.physician_last_name,
                        phone: payload?.data?.physician_phone_number,
                        npi: payload?.data?.physician_credential,
                        hospitalClinic: payload?.data?.physician_hospital_name,
                        hospitalAddress: payload?.data?.physician_hospital_address
                    });

                    // Set prescription type
                    setPrescriptionType(payload.data.type);
                    setDate_issued(payload?.data?.date_issued)
                    setStatus(payload?.data?.status)

                    // Populate medications if exists
                    if (payload?.data?.medications && payload?.data?.medications.length > 0) {
                        setMedications(payload?.data?.medications);
                    }
                })
                .catch((error) => console.error('Error fetching prescription details:', error));
        }
    }, [dispatch, prescriptionId, prescriptionDetail.status])
    const isPhysicianInfoFilled = Object.values(physicianInfo).every(value => value !== '');


    const handleSubmit = async () => {
        const prescriptionData = {
            type: prescriptionType,
            patient_id: patientInfo.id,
            physician_first_name: physicianInfo.firstName,
            physician_last_name: physicianInfo.lastName,
            physician_credential: physicianInfo.npi,
            physician_phone_number: physicianInfo.phone,
            physician_hospital_name: physicianInfo.hospitalClinic,
            physician_hospital_address: physicianInfo.hospitalAddress,
            medications,
            date_issued
        };

        try {
            const required = !isPhysicianInfoFilled || !medications || !date_issued
            if (required) {
                toast.warn('Kindly fill all fields')
                return;
            }

            const resultAction = await dispatch(updatePrescription({ prescriptionId, prescriptionData }));

            if (updatePrescription.fulfilled.match(resultAction)) {
                toast.success('Prescription updated successfully');
            }
        } catch (error) {
            console.error('Failed to update prescription:', error);
            toast.error('Failed to update prescription');
        }
    };

    const updateStatus = async () => {
        try {
            await dispatch(updatePrescriptionStatus({ prescriptionId })).unwrap();
            // Refetch the prescription details to update the entire prescription state
            dispatch(fetchPrescription(prescriptionId));
            console.log('Prescription status updated successfully');
        } catch (error) {
            console.error('Error updating prescription status:', error);
            toast.error('Failed to update prescription status');
        }
    };
    const cancelStatus = async () => {
        try {
            await dispatch(updatePrescriptionStatus({
                prescriptionId,
                action: 'cancel'
            }))
                .unwrap()
            // Refetch the prescription details to update the entire prescription state
            dispatch(fetchPrescription(prescriptionId));
            console.log('Prescription status cancelled successfully');
        } catch (error) {
            console.error('Error canceling prescription status:', error);
            toast.error('Failed to cancel prescription status');
        }
    }

    const backgroundColor = getStatusBackgroundColor(prescriptionDetail.status);


    return (
        <div className="font-lufga w-full">
            <div className='mb-2 mx-8 flex justify-between items-center'>
                <div>
                    <p className='lg:text-3xl text-xl font-semibold text-slate-600'>
                        {isEditing ? 'Edit Prescription' : 'Prescription Details'}
                    </p>
                    <button
                        onClick={() => { navigate(-1) }}
                        className='text-sm font-semibold text-slate-600 hover:underline'
                    >
                        Go back
                    </button>
                </div>
               <div className='flex flex-col sm:flex-row gap-2'>
              {hasPermission(roles, ['inventoryWriter', 'inventoryAdmin']) &&( <button
                    onClick={() => setIsEditing(!isEditing)}
                    className='bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600'
                >
                    {isEditing ? 'Cancel' : 'Edit'}
                </button>)}
               <button
                    onClick={toggleCommentPane}
                    className='bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600'
                >
                   View Comments
                </button>
               </div>
            </div>

            <form onSubmit={handleSubmit} className='p-2 sm:p-4 max-w-[1440px] mx-auto'>
                <div className='flex justify-between '>
                    <div className="mb-4 w-fit">
                        <label className="font-medium mb-2">Prescription Type</label>
                        {renderInput('select', prescriptionType, handlePrescriptionTypeChange, {
                            children: (
                                <>
                                    <option value="verbal">Verbal/Phone</option>
                                    <option value="written">Written/Paper</option>
                                    {/* <option value="eprescription">E-Prescription</option> */}
                                </>
                            ),
                            required: true
                        })}
                    </div>
                    <div className='mb-4 w-fit flex flex-col'>
                        <label className="font-medium ">Status: <span className={`${backgroundColor}`}>{prescriptionDetail.status}</span></label>
                        <div className='flex items-center gap-2'>
                            {!['cancelled', 'fulfilled'].includes(prescriptionDetail.status) && (
                                <>
                                    <button
                                        type="button"
                                        onClick={updateStatus}
                                        className="bg-green-500 hover:opacity-90 text-white py-1 px-2 rounded-md shadow-sm text-xs"
                                    >
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        onClick={cancelStatus}
                                        className="bg-red-500 hover:opacity-90 text-white py-1 px-2 rounded-md shadow-sm text-xs"
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="p-4 border-b shadow rounded-md mb-4">
                    <h2 className="text-lg font-medium mb-2">Patient Info</h2>
                    <div className="grid sm:grid-cols-2 gap-4">
                        <div>
                            <label className="block font-medium text-sm">First Name</label>
                            {renderInput('text', patientInfo.firstName, null, { readOnly: true })}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Last Name</label>
                            {renderInput('text', patientInfo.lastName, null, { readOnly: true })}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Date of Birth</label>
                            {renderInput('date', patientInfo.dob, null, { readOnly: true })}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Gender</label>
                            {renderInput('text', patientInfo.gender, null, { readOnly: true })}
                        </div>

                        <div>
                            <label className="block font-medium text-sm">Phone</label>
                            {renderInput('text', patientInfo.phone, null, { readOnly: true })}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Address</label>
                            {renderInput('text', patientInfo.address, null, { readOnly: true })}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">ID Type</label>
                            {renderInput('text', patientInfo.idType, null, { readOnly: true })}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">ID Number</label>
                            {renderInput('text', patientInfo.idNumber, null, { readOnly: true })}
                        </div>
                    </div>
                </div>

                <div className="p-4 border-b shadow rounded-md mb-4">
                    <h2 className="text-lg font-medium mb-2">Provider Info</h2>
                    <div className="grid sm:grid-cols-2 gap-4">
                        <div>
                            <label className="block font-medium text-sm">First Name</label>
                            {renderInput('text', physicianInfo.firstName, (value) => handlePhysicianInfoChange('firstName', value))}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Last Name</label>
                            {renderInput('text', physicianInfo.lastName, (value) => handlePhysicianInfoChange('lastName', value))}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Provider ID</label>
                            {renderInput('text', physicianInfo.npi, (value) => handlePhysicianInfoChange('npi', value))}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Phone</label>
                            {renderInput('text', physicianInfo.phone, (value) => handlePhysicianInfoChange('phone', value))}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Hospital/Clinic</label>
                            {renderInput('text', physicianInfo.hospitalClinic, (value) => handlePhysicianInfoChange('hospitalClinic', value))}
                        </div>
                        <div>
                            <label className="block font-medium text-sm">Hospital Address</label>
                            {renderInput('text', physicianInfo.hospitalAddress, (value) => handlePhysicianInfoChange('hospitalAddress', value))}
                        </div>
                    </div>
                </div>

                <div className="p-4 border-b shadow rounded-md">
                    <h2 className="text-lg font-medium mb-2">Medication Info</h2>
                    {medications.map((medication, index) => (
                        <div key={index} className="border rounded p-4 mb-4 relative">
                            {isEditing && medications.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => removeMedication(index)}
                                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-600"
                                >
                                    Remove
                                </button>
                            )}
                            <div className="grid sm:grid-cols-2 gap-4">
                                <div>
                                    <label className="block font-medium text-sm">Medication Name</label>
                                    {renderInput('text', medication.name, (value) => handleMedicationChange(index, 'name', value))}
                                </div>
                                <div>
                                    <label className="block font-medium text-sm">Strength (Dosage)</label>
                                    {renderInput('text', medication.strength, (value) => handleMedicationChange(index, 'strength', value))}
                                </div>
                                <div>
                                    <label className="block font-medium text-sm">Quantity</label>
                                    {renderInput('number', medication.quantity, (value) => handleMedicationChange(index, 'quantity', value))}
                                </div>
                                <div>
                                    <label className="block font-medium text-sm">No. of Refills</label>
                                    {renderInput('number', medication.refills, (value) => handleMedicationChange(index, 'refills', value))}
                                </div>
                                <div>
                                    <label className="block font-medium text-sm">Instructions</label>
                                    {renderInput('textarea', medication.instructions, (value) => handleMedicationChange(index, 'instructions', value), {
                                        className: 'h-20'
                                    })}
                                </div>


                            </div>
                        </div>
                    ))}
                    {isEditing && (
                        <div className="flex space-x-2">
                            <button
                                type="button"
                                onClick={addMedication}
                                className="bg-gray-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs"
                            >
                                Add Medication
                            </button>
                        </div>
                    )}
                </div>


                <div className={`p-4 mt-4 border-b shadow rounded-md `}>

                    <div>
                        <label htmlFor="date_issued" className="block font-medium text-sm">Date Issued</label>
                        {renderInput('date', date_issued, (value) => handleDateIssuedChange(value))}
                    </div>
                </div>


                {isEditing && (
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={handleSubmit}
                            type="button"
                            className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-sm"
                        >
                            Save Changes
                        </button>
                    </div>
                )}
            </form>

            <div className={`fixed top-0 z-50 right-0 h-full w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${isCommentPaneOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="py-4 pl-2">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">Comments</h2>
                        <button onClick={toggleCommentPane} className="text-gray-500 hover:text-gray-700">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    {/* Add your comments content here */}
                    <PrescriptionComments prescription={prescriptionDetail} prescriptionId={prescriptionId} roles={roles}/>
                </div>
            </div>
        </div>
    );
};

export default ViewEditPrescription;