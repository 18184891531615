import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPatientAllergy } from '../../../Redux/AllergySlice';
import { toast } from 'react-toastify';

const AddAllergyModal = ({ isOpen, onClose,patientId }) => {
  const [currentAllergy, setCurrentAllergy] = useState({
    name: '',
    onset_date: '',
    reaction: ''
  });
  const dispatch = useDispatch()

  // Handle adding the allergy
  const handleAddAllergy = async () => {
    if (!patientId) {
        console.error('Patient ID is missing.');
        return;
    }
    try {
        const response = await dispatch(
            addPatientAllergy({
                allergies:[ currentAllergy],
                patientId,
            })
        ).unwrap();
        toast.success('Allergy added successfully')
        onClose()

    } catch (error) {
        console.error('Failed to add allergy:', error);
    }
};

  // If modal is not open, return null
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Add New Allergy</h2>
          <p className="text-sm text-gray-600 mt-1">Enter details for the new allergy</p>
        </div>

        <div className="space-y-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="name" className="text-right font-medium text-gray-700">
              Name
            </label>
            <input 
              id="name"
              value={currentAllergy.name}
              onChange={(e) => setCurrentAllergy({...currentAllergy, name: e.target.value})}
              className="col-span-3 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Allergy name"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="onset_date" className="text-right font-medium text-gray-700">
              Onset Date
            </label>
            <input 
              id="onset_date"
              type="date"
              value={currentAllergy.onset_date}
              onChange={(e) => setCurrentAllergy({...currentAllergy, onset_date: e.target.value})}
              className="col-span-3 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="reaction" className="text-right font-medium text-gray-700">
              Reaction
            </label>
            <textarea 
              id="reaction"
              value={currentAllergy.reaction}
              onChange={(e) => setCurrentAllergy({...currentAllergy, reaction: e.target.value})}
              className="col-span-3 border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Describe the allergic reaction"
              rows="3"
            />
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-3">
          <button 
            onClick={onClose}
            className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button 
            onClick={handleAddAllergy}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
          >
            Add Allergy
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAllergyModal;

// Example usage in a parent component:
// function ParentComponent() {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [allergies, setAllergies] = useState([]);
// 
//   const handleAddAllergy = (newAllergy) => {
//     setAllergies([...allergies, newAllergy]);
//   };
// 
//   return (
//     <>
//       <button onClick={() => setIsModalOpen(true)}>Add Allergy</button>
//       <AddAllergyModal 
//         isOpen={isModalOpen} 
//         onClose={() => setIsModalOpen(false)}
//         onAddAllergy={handleAddAllergy}
//       />
//     </>
//   );
// }