import React, { useState, useRef, useEffect } from 'react';
import { CiExport, CiMenuKebab } from 'react-icons/ci';
import { MdOutlineAutoDelete } from 'react-icons/md';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import { ColorRing } from 'react-loader-spinner';
import { FcSearch } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectRoles } from '../../../Redux/AuthSlice';
import { hasPermission } from '../../../Redux/permissionSlice';
import { BsEye } from 'react-icons/bs';
import { clearFilters, fetchPrescriptions, setFilters, setSorting } from '../../../Redux/PrescriptionSlice';
import DynamicPagination from '../../../utils/DynamicPagination';
import { toast } from 'react-toastify';
import { FaChevronDown } from 'react-icons/fa';

const PrescriptionTableInfo = () => {
  const location = useLocation()
  const [searchLoading, setSearchLoading] = useState(false)
  const selectedRoles = useSelector(selectRoles);
  const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());
  const { id, branch_id } = useSelector((state) => state.auth)

  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const popUpRef = useRef(null);
  const status = ['*', 'All', 'Fulfilled', 'Pending', 'Processing','Ready for PickUp','Awaiting Confirmation']
  const [selectedStatus, setSelectedStatus] = useState('');





  // State for managing sort and filter UI..
  const [localFilters, setLocalFilters] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    prescription_type: ''
  });
  const [sortConfig, setSortConfig] = useState({
    first_name: null,
    last_name: null,
    date_created: null
  });

  const handleSort = (field) => {
    let newSortValue;

    if (sortConfig[field] === null) {
      newSortValue = `${field}`;
      setSortConfig(prev => ({ ...prev, [field]: 'asc' }));
    } else if (sortConfig[field] === 'asc') {
      newSortValue = `-${field}`;
      setSortConfig(prev => ({ ...prev, [field]: 'desc' }));
    } else {
      newSortValue = null;
      setSortConfig(prev => ({ ...prev, [field]: null }));
    }

    dispatch(setSorting(newSortValue));

    dispatch(fetchPrescriptions({
      userId: 574,
      branchId: 2,
      page: meta.current_page,
      limit: meta.per_page,
      sort: newSortValue,
      filters
    }));
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const newFilters = {
      ...localFilters,
      [name]: value.trim()
    };

    setLocalFilters(newFilters);
  };

  // Apply filters
  const applyFilters = () => {

    // Prepare the combined filter with non-empty values
    const combinedFilters = {};
    const filterFields = [];
    const filterValues = [];


    if (localFilters.first_name.trim() && localFilters.last_name.trim() && localFilters.date_of_birth.trim()) {
      filterFields.push('search_patient');
      filterValues.push(localFilters.first_name.trim());
      filterValues.push(localFilters.last_name.trim());
      filterValues.push(localFilters.date_of_birth.trim());

    }
    if (localFilters.prescription_type.trim()) {
      filterFields.push('prescription_type');
      filterValues.push(localFilters.prescription_type.trim());
    }

    // If there are any filters, create the combined filter
    if (filterFields.length > 0) {
      combinedFilters[filterFields.join(',')] = filterValues.join(',');
    }

    dispatch(setFilters(combinedFilters));

    dispatch(fetchPrescriptions({
      userId: 574,
      branchId: 2,
      page: 1,
      limit: meta.per_page,
      sort,
      filters: combinedFilters
    }));
  };

  // Reset filters
  const resetFilters = () => {
    setLocalFilters({
      first_name: '',
      last_name: '',
      date_of_birth: '',
      prescription_type: ''
    });
    dispatch(clearFilters());
    dispatch(fetchPrescriptions({
      userId: 574,
      branchId: 2,
      page: 1,
      limit: meta.per_page
    }));
  };

  // Toggle dropdown
  const togglePopUp = (event, id) => {
    event.stopPropagation();
    setSelectedDropdown(selectedDropdown === id ? null : id);
  };



  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setSelectedDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [branch_id, id]);

  const dispatch = useDispatch();
  const {
    prescriptions,
    loading,
    error,
    meta,
    sort,
    filters
  } = useSelector((state) => state.prescriptions);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    dispatch(fetchPrescriptions({
      page: currentPage,
      limit: itemsPerPage,
      userId: id,
      branchId: branch_id,
    }));
  }, [dispatch, currentPage]);




  if (loading) {
    return (<div className="flex items-center justify-center h-full">
      <ColorRing
        height="50"
        width="50"
        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
      <p>Loading prescriptions...</p>
    </div>)
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='font-lufga'>
      <div className='mb-2 mx-8 '>
        <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Prescriptions</p>
      </div>

      {prescriptions.length === 0 ? (
        <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)] gap-4'>
          <p className='text-xl text-gray-500'>No prescriptions found</p>
          {/* {hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin']) && (
            <Link to='/retail/prescriptions/add'>
              <button className="bg-green-500 hover:opacity-90 text-white py-2 px-6 rounded-md shadow-sm">
                Add Prescription
              </button>
            </Link>
          )} */}
        </div>
      ) : (
        <>
          <div className='flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'>
            <div className=''>
              <div className='flex flex-wrap items-center gap-2'>
                <div className='flex flex-col gap-1'>
                  <label className='text-xs'>First Name</label>
                  <input
                    type='text'
                    name='first_name'
                    placeholder='First Name'
                    value={localFilters.first_name}
                    onChange={handleFilterChange}
                    className='w-32 focus:outline-none rounded-md shadow-sm border px-2 py-2 h-3 font-lufga text-xs'
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <label className='text-xs'>Last Name</label>
                  <input
                    type='text'
                    name='last_name'
                    placeholder='Last Name'
                    value={localFilters.last_name}
                    onChange={handleFilterChange}
                    className='w-32 focus:outline-none rounded-md shadow-sm border px-2 py-2 h-3 font-lufga text-xs'
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <label className='text-xs'>Date of Birth</label>
                  <input
                    type='date'
                    name='date_of_birth'
                    placeholder='Date of Birth'
                    value={localFilters.date_of_birth}
                    onChange={handleFilterChange}
                    className='w-36 focus:outline-none rounded-md shadow-sm border px-2 py-2 h-3 font-lufga text-xs'
                  />
                </div>
                {searchLoading ? (
                  <ColorRing
                    height="20"
                    width="20"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                  />
                ) : (
                  <FcSearch
                    onClick={applyFilters}
                    className='hover:scale-110 cursor-pointer'
                  />
                )}
              </div>
            </div>
            <div className='flex flex-col sm:flex-row items-center gap-4'>


              {hasPermission(roles, ['prescriptionWriter', 'prescriptionAdmin']) && (
                <Link to='/retail/prescriptions/add'>
                  <button className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">
                    Add Prescription
                  </button>
                </Link>
              )}

            </div>
          </div>



          <table className='w-full text-xs px-2 table-auto overflow-auto shadow-md'>
            <thead className='bg-green-200 shadow-lg sticky top-0'>
              <tr>
                <th className=''>No.</th>
                <th>ID</th>
                <th className="">
                  <div className='flex justify-center items-center gap-1'>
                    <p>Patient Name</p>
                    {sortConfig.full_name === null ? (
                      <IoIosArrowRoundDown
                        onClick={() => handleSort('first_name')}
                        className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                      />
                    ) : sortConfig.full_name === 'asc' ? (
                      <IoIosArrowRoundUp
                        onClick={() => handleSort('first_name')}
                        className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                      />
                    ) : (
                      <IoIosArrowRoundDown
                        onClick={() => handleSort('first_name')}
                        className="text-sm cursor-pointer hover:bg-slate-50 rounded-md text-red-500"
                      />
                    )}
                  </div>
                </th>
                <th className=''>Patient contact</th>
                <th className=''>Prescription Type</th>
                <th className=''>Provider</th>
                <th className=" ">
                  <div className="relative">
                    <span className="flex items-center justify-center gap-1 font-semibold cursor-pointer">
                      <p>Status</p>
                      <FaChevronDown size={10} />
                    </span>
                    <select
                      id="status"
                      name="status"
                      value={selectedStatus}
                      // onChange={(e) => { handleStatusChange(e.target.value); }}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    >
                      {status.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </th>
                <th className=''>Branch</th>
                <th className="">
                  <div className='flex justify-center items-center'>
                    <p>Date Created</p>
                    {sortConfig.date_created === null ? (
                      <IoIosArrowRoundDown
                        onClick={() => handleSort('date_created')}
                        className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                      />
                    ) : sortConfig.date_created === 'asc' ? (
                      <IoIosArrowRoundUp
                        onClick={() => handleSort('date_created')}
                        className="text-sm cursor-pointer hover:bg-slate-50 rounded-md"
                      />
                    ) : (
                      <IoIosArrowRoundDown
                        onClick={() => handleSort('date_created')}
                        className="text-sm cursor-pointer hover:bg-slate-50 rounded-md text-red-500"
                      />
                    )}
                  </div>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className='py-2'>
              {prescriptions.map((prescription, index) => (
                <tr
                  key={prescription.id}
                  className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}
                >
                  <td className='border-r-2 text-center py-4 h-20 flex-wrap'>{(meta?.current_page - 1) * meta?.per_page + index + 1}</td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription?.id}</td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{`${prescription?.patient_first_name} ${prescription?.patient_last_name}`}</td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription?.patient_phone_number}</td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription?.type}</td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{`${prescription?.physician_first_name} ${prescription?.physician_last_name}`}</td>
                  <td
                    className={`border-x-2 text-center py-4 h-20 flex-wrap ${prescription?.status === "cancelled" ? "bg-red-100 text-red-600" :
                      prescription?.status === "pending" ? "bg-yellow-100 text-yellow-700" :
                        prescription?.status === "processing" ? "bg-orange-100 text-orange-700" :
                          prescription?.status === "awaiting_confirmation" ? "bg-purple-100 text-purple-700" :
                            prescription?.status === "pick_up" ? "bg-blue-100 text-blue-700" :
                              prescription?.status === "fulfilled" ? "bg-green-100 text-green-700" : ""
                      }`}
                  >
                    {prescription?.status === "cancelled" && "Cancelled"}
                    {prescription?.status === "pending" && "Pending"}
                    {prescription?.status === "processing" && "Processing"}
                    {prescription?.status === "awaiting_confirmation" && "Awaiting Confirmation"}
                    {prescription?.status === "pick_up" && "Ready for Pick Up"}
                    {prescription?.status === "fulfilled" && "Fulfilled"}
                  </td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription?.branch_name}</td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap'>
                    {new Date(prescription.created_at).toLocaleDateString()}
                  </td>
                  <td className='border-x-2 text-center py-4 h-20 flex-wrap text-green-600 cursor-pointer'>
                    <Link to={`/retail/prescriptions/view-prescription/${prescription.id}`}
                      state={{ requiredRoles: ['prescriptionViewer', 'prescriptionWriter', 'prescriptionAdmin'] }}
                      className=' hover:underline'
                    >
                      <span>view</span>

                    </Link>
                  </td>


                </tr>
              ))}
            </tbody>
          </table>

        </>
      )}

      <DynamicPagination
        currentPage={meta.current_page}
        totalPages={meta.last_page}
        onPageChange={(page) => {
          dispatch(fetchPrescriptions({
            userId: 574,
            branchId: 2,
            page,
            limit: meta.per_page,
            sort,
            filters
          }));
        }}
      />
    </div>
  );
};

export default PrescriptionTableInfo;