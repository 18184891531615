import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import salesReducer from './Redux/SalesSlice';
import permissionsReducer from './Redux/permissionSlice';
import prescriptionReucer from './Redux/PrescriptionSlice';
import patientReducer from './Redux/PatientSlice';
import allergyReducer from './Redux/AllergySlice'




const store = configureStore({
  reducer: {
  auth : authReducer,
  sales: salesReducer,
  permissions: permissionsReducer,
  prescriptions: prescriptionReucer,
  patients:patientReducer,
  allergy: allergyReducer


  },
});

export default store;
