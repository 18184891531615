import React from 'react';

const DynamicPagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    // Ensure currentPage is always at least 1
    const safePage = Math.max(1, currentPage);
    
    // Calculate page range
    let startPage = Math.max(1, safePage - 2);
    let endPage = Math.min(totalPages, safePage + 2);

    // Adjust range to always show 5 pages or less if total pages is less
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // Ensure we have 5 pages when possible
      if (safePage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (safePage >= totalPages - 2) {
        startPage = totalPages - 4;
        endPage = totalPages;
      }
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`px-3 py-1 rounded cursor-pointer ${
            i === safePage ? 'bg-green-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </li>
      );
    }

    return pageNumbers;
  };

  // Only render pagination if there's more than one page
  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex justify-center mt-4">
      <ul className="flex space-x-2">{renderPageNumbers()}</ul>
    </div>
  );
};

export default DynamicPagination;