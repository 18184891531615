import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';
import { api_url } from '../config';

const initialState = {
  prescriptions: [],
  prescriptionDetail: {},
  prescriptionComments:[],
  loading: false,
  error: null,
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0
  },
  // New state for sorting and filtering
  sort: null,
  filters: {}
};

export const fetchPrescriptions = createAsyncThunk(
  'prescriptions/fetchPrescriptions',
  async ({
    page = 1,
    limit = 15,
    userId,
    branchId,
    sort = null,
    filters = {}
  }, { getState }) => {
    const { auth: { token } } = getState();

    // Construct query parameters
    const params = { page, limit };

    // Add sorting
    if (sort) {
      params.sort = sort;
    }

    // Add filters
    Object.keys(filters).forEach(key => {
      params[`filter[${key}]`] = filters[key];
    });

    const response = await api.get(
      `${api_url}/api/v1/retailer/user/${userId}/branch/${branchId}/prescription`,
      {
        params,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }
    );

    // Ensure we return data, meta, sort, and filters
    return {
      data: response.data.data || [],
      meta: response.data.meta || {
        current_page: page,
        last_page: 1,
        per_page: limit,
        total: 0
      },
      sort,
      filters
    };
  }
);

export const addPrescription = createAsyncThunk(
  'prescriptions/addPrescription',
  async (prescriptionData, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id, branch_id } } = getState();

      const response = await api.post(
        `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/prescription`,
        prescriptionData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      // Handle specific error scenarios
      return rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        'Failed to add prescription'
      );
    }
  }
);
export const addComment = createAsyncThunk(
  'prescriptions/addComment',
  async ({ comment, prescription_id }, thunkAPI) => {
    try {
      const { auth: { token, id, branch_id } } = thunkAPI.getState();

      const response = await api.post(
        `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/prescription/${prescription_id}/comments`,
        { content: comment }, // Wrap comment in an object
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      // Handle specific error scenarios
      return thunkAPI.rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        'Failed to add comments'
      );
    }
  }
);


export const updatePrescription = createAsyncThunk(
  'prescriptions/updatePrescription',
  async ({ prescriptionId, prescriptionData }, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id, branch_id } } = getState();

      const response = await api.put(
        `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/prescription/${prescriptionId}`,
        prescriptionData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        'Failed to update prescription'
      );
    }
  }
);


export const updatePrescriptionStatus = createAsyncThunk(
  'prescriptions/updatePrescriptionStatus',
  async ({ prescriptionId, action }, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id, branch_id }, prescriptions } = getState();
      
      // Find the current prescription
      const currentPrescription = prescriptions.prescriptionDetail
      

      if (!currentPrescription) {
        return rejectWithValue('Prescription not found');
      }

      // Prepare the request data
      let prescriptionData;

      // If the action is explicitly to cancel, set status to cancelled
      if (action === 'cancel') {
        prescriptionData = { status: 'cancelled' };
      } else {
        // Normal progression logic
        let newStatus;
        switch (currentPrescription.status) {
          case 'pending':
            newStatus = 'processing';
            break;
          case 'processing':
            newStatus = 'awaiting_confirmation';
            break;
          case 'awaiting_confirmation':
            newStatus = 'pick_up';
            break;
          case 'pick_up':
            newStatus = 'fulfilled';
            break;
          default:
            return rejectWithValue('Invalid current status');
        }
        prescriptionData = { status: newStatus };
      }

      // Make the API call to update status
      const response = await api.put(
        `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/prescription/${prescriptionId}/status`,
        prescriptionData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        'Failed to update prescription status'
      );
    }
  }
);


export const fetchPrescription = createAsyncThunk(
  'prescriptions/fetchPrescription',
  async (prescriptionId, { getState, rejectWithValue }) => {
    try {

      const { auth: { token, id, branch_id } } = getState();

      const response = await api.get(
        `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/prescription/${prescriptionId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        'Failed to update prescription'
      );
    }
  }
);
export const fetchPrescriptionComments = createAsyncThunk(
  'prescriptions/fetchPrescriptionComments',
  async (prescriptionId, { getState, rejectWithValue }) => {
    try {

      const { auth: { token, id, branch_id } } = getState();

      const response = await api.get(
        `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/prescription/${prescriptionId}/comments`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        'Failed to update prescription'
      );
    }
  }
);

const prescriptionsSlice = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {
    // Optional but if you will implement becareful: Add actions to manually set sort and filters
    setSorting: (state, action) => {
      state.sort = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearFilters: (state) => {
      state.filters = {};
      state.sort = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrescriptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPrescriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptions = action.payload.data;
        state.meta = action.payload.meta;
        // Update sort and filters from the payload
        state.sort = action.payload.sort;
        state.filters = action.payload.filters;
      })
      .addCase(fetchPrescriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPrescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPrescription.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionDetail = action.payload.data;

      })
      .addCase(fetchPrescription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPrescriptionComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPrescriptionComments.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionComments = action.payload.data;
        

      })
      .addCase(fetchPrescriptionComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addPrescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPrescription.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptions.unshift(action.payload);
        state.currentPrescription = null;
      })
      .addCase(addPrescription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
        state.prescriptionComments.unshift(action.payload.data);
      })
      .addCase(addComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePrescription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePrescription.fulfilled, (state, action) => {
        state.loading = false;
        // Update the prescription in the list
        const index = state.prescriptions.findIndex(
          p => p.id === action.payload.id
        );
        if (index !== -1) {
          state.prescriptions[index] = action.payload;
        }
        state.currentPrescription = null;
      })
      .addCase(updatePrescription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePrescriptionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePrescriptionStatus.fulfilled, (state, action) => {
        state.loading = false;
        // Update the prescription in the list
        const index = state.prescriptions.findIndex(
          p => p.id === action.payload.id
        );
        if (index !== -1) {
          state.prescriptions[index] = action.payload;
        }
        state.currentPrescription = null;
      })
      .addCase(updatePrescriptionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions for sorting and filtering
export const { setSorting, setFilters, clearFilters } = prescriptionsSlice.actions;

export default prescriptionsSlice.reducer;