import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';
import { api_url } from '../config';
import { toast } from 'react-toastify';

const initialState = {
  allergies: [], // List of allergies for a patient
  currentAllergy: null, // Currently selected/active allergy
  loading: false,
  error: null,
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0
  }
};

// Fetch allergies for a specific patient
export const fetchPatientAllergies = createAsyncThunk(
  'allergies/fetchPatientAllergies',
  async ({ patientId }, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id: userId, branch_id } } = getState();

      const response = await api.get(
        `${api_url}/api/v1/retailer/user/${userId}/branch/${branch_id}/patient/${patientId}/allergies`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 
        error.message || 
        'Failed to fetch patient allergies'
      );
    }
  }
);

// Add an allergy to a patient
export const addPatientAllergy = createAsyncThunk(
  'allergies/addPatientAllergy',
  async ({ allergies , patientId}, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id: userId, branch_id } } = getState();

      const response = await api.post(
        `${api_url}/api/v1/retailer/user/${userId}/branch/${branch_id}/patient/${patientId}/allergies`,
       { allergies},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 
        error.message || 
        'Failed to add patient allergy'
      );
    }
  }
);

// Update an existing allergy for a patient
export const updatePatientAllergy = createAsyncThunk(
  'allergies/updatePatientAllergy',
  async ({ patientId, allergyId, allergies }, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id: userId, branch_id } } = getState();

      const response = await api.put(
        `${api_url}/api/v1/retailer/user/${userId}/branch/${branch_id}/patient/${patientId}/allergies/${allergyId}`,
      allergies,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );
      toast.success('Allergy updated succesfully')
      
      return response.data;
    } catch (error) {
        toast.error('Allergy failed to update')

      return rejectWithValue(
        error.response?.data?.message || 
        error.message || 
        'Failed to update patient allergy'
      );
      
    }
  }
);

// Delete an allergy for a patient
export const deletePatientAllergy = createAsyncThunk(
  'allergies/deletePatientAllergy',
  async ({ patientId, allergyId }, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id: userId, branch_id } } = getState();

      await api.delete(
        `${api_url}/api/v1/retailer/user/${userId}/branch/${branch_id}/patient/${patientId}/allergies/${allergyId}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return allergyId;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 
        error.message || 
        'Failed to delete patient allergy'
      );
    }
  }
);

const allergiesSlice = createSlice({
  name: 'allergies',
  initialState,
  reducers: {
    // Optional: Clear current allergy or reset state
    clearCurrentAllergy: (state) => {
      state.currentAllergy = null;
    },
    // Optional: Set current allergy for editing
    setCurrentAllergy: (state, action) => {
      state.currentAllergy = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch Allergies Cases
      .addCase(fetchPatientAllergies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientAllergies.fulfilled, (state, action) => {
        state.loading = false;
        state.allergies = action.payload|| [];
        state.meta = action.payload.meta || state.meta;
      })
      .addCase(fetchPatientAllergies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allergies = [];
      })

      // Add Allergy Cases
      .addCase(addPatientAllergy.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPatientAllergy.fulfilled, (state, action) => {
        state.loading = false;
        
        // Add the new allergies to the beginning of the list
        state.allergies = [...action.payload, ...state.allergies];
        
        state.currentAllergy = null;
    })
      .addCase(addPatientAllergy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Allergy Cases
      .addCase(updatePatientAllergy.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePatientAllergy.fulfilled, (state, action) => {
        
        
        state.loading = false;
        // Find and update the allergy in the list
        const index = state.allergies.findIndex(
          a => a.id === action.payload.id
        );
        if (index !== -1) {
          state.allergies[index] = action.payload;
        }
        state.currentAllergy = null;

       
      })
      .addCase(updatePatientAllergy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Allergy Cases
      .addCase(deletePatientAllergy.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePatientAllergy.fulfilled, (state, action) => {
        state.loading = false;
        // Remove the deleted allergy from the list
        state.allergies = state.allergies.filter(
          a => a.id !== action.payload
        );
        state.currentAllergy = null;
      })
      .addCase(deletePatientAllergy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions
export const { 
  clearCurrentAllergy, 
  setCurrentAllergy 
} = allergiesSlice.actions;

export default allergiesSlice.reducer;