import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';
import { api_url } from '../config';

const initialState = {
  patients: [],
  patientDetail:{},
  loading: false,
  error: null,
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0
  },
  // New state for sorting and filtering
  sort: null,
  filters: {}
};

export const fetchPatients = createAsyncThunk(
  'patients/fetchPatients',
  async ({ 
    page = 1, 
    limit = 15, 
    userId, 
    branchId, 
    sort = null, 
    filters = {} 
  }, { getState }) => {
    const { auth: { token } } = getState();

    // Construct query parameters
    const params = { page, limit };

    // Add sorting
    if (sort) {
      params.sort = sort;
    }

    // Add filters
    Object.keys(filters).forEach(key => {
      params[`filter[${key}]`] = filters[key];
    });

    const response = await api.get(
      `${api_url}/api/v1/retailer/user/${userId}/branch/${branchId}/patient`, 
      {
        params,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      }
    );

    // Ensure we return data, meta, sort, and filters
    return {
      data: response.data.data || [],
      meta: response.data.meta || {
        current_page: page,
        last_page: 1,
        per_page: limit,
        total: 0
      },
      sort,
      filters
    };
  }
);

export const addPatient = createAsyncThunk(
    'patients/addPatient',
    async (patientData, { getState, rejectWithValue }) => {
      try {
        const { auth: { token, id, branch_id } } = getState();
  
        const response = await api.post(
          `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/patient`,
          patientData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
  
        return response.data;
      } catch (error) {
        // Handle specific error scenarios
        return rejectWithValue(
          error.response?.data?.message || 
          error.message || 
          'Failed to add patient'
        );
      }
    }
  );

  export const fetchPatientList = createAsyncThunk(
    'patients/fetchPatientList',
    async (_,{ getState, rejectWithValue }) => {
      try {
        const { auth: { token, id, branch_id } } = getState();
  
        const response = await api.get(
          `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/patient-list`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
  
        return response.data;
      } catch (error) {
        // Handle specific error scenarios
        return rejectWithValue(
          error.response?.data?.message || 
          error.message || 
          'Failed to add patient'
        );
      }
    }
  );

  export const fetchPatient = createAsyncThunk(
    'prescriptions/fetchPatient',
    async ({patientId}, { getState, rejectWithValue }) => {
      try {
  
        const { auth: { token, id, branch_id } } = getState();
  
        const response = await api.get(
          `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/patient/${patientId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
  
        return response.data;
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message ||
          error.message ||
          'Failed to update prescription'
        );
      }
    }
  );
  

  export const updatePatient = createAsyncThunk(
    'patients/updatePatient',
    async ({ patientId, patientData }, { getState, rejectWithValue }) => {
      try {
        const { auth: { token, id, branch_id } } = getState();
  
        const response = await api.put(
          `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/patient/${patientId}`,
          patientData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
        );
  
        return response.data;
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || 
          error.message || 
          'Failed to update patient'
        );
      }
    }
  );

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    // Optional but if you will implement becareful: Add actions to manually set sort and filters
    setSorting: (state, action) => {
      state.sort = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    clearFilters: (state) => {
      state.filters = {};
      state.sort = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = action.payload.data;
        state.meta = action.payload.meta;
        // Update sort and filters from the payload
        state.sort = action.payload.sort;
        state.filters = action.payload.filters;
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patientDetail = action.payload.data;
        
      })
      .addCase(fetchPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPatientList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientList.fulfilled, (state, action) => {
        state.loading = false;
        state.patients = action.payload.data;
        state.meta = action.payload.meta;
        // Update sort and filters from the payload
        state.sort = action.payload.sort;
        state.filters = action.payload.filters;
      })
      .addCase(fetchPatientList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPatient.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally add the new patient to the list
        state.patients.unshift(action.payload);
        state.currentPatient = null;
      })
      .addCase(addPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Add new cases for updatePatient
      .addCase(updatePatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.loading = false;
        // Update the patient in the list
        const index = state.patients.findIndex(
          p => p.id === action.payload.id
        );
        if (index !== -1) {
          state.patients[index] = action.payload;
        }
        state.currentPatient = null;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions for sorting and filtering
export const { setSorting, setFilters, clearFilters } = patientsSlice.actions;

export default patientsSlice.reducer;